import { useState, useEffect } from "react";
import "./Slideshow.css"; // Arquivo CSS separado

const ImageSlideshow = ({ images, ajusteImgExtra, interval = 5000 }) => {

    console.log('images: ', images)

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, interval);

        return () => clearInterval(timer);
    }, [images, interval]);

    return (
        <div className="slideshow-container boxEstatisticasDois">
            {images.map((img, i) => (
                <div
                    key={i}
                    className={`slide ${i === index ? "active" : ""}`}
                    style={{ backgroundImage: `url(${img.url})`, backgroundSize: ajusteImgExtra || "cover" }}
                />
            ))}
        </div>
    );
};

export default ImageSlideshow;
