
import './error.css'



export default function Error() {



    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            textAlign: "center",
            backgroundColor: "#fff",
            color: "#721c24",
            padding: "20px",
        },
        title: {
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
        },
        message: {
            fontSize: "18px",
            marginBottom: "20px",
        },
        buttonContainer: {
            display: "flex",
            gap: "10px",
        },
        button: {
            padding: "10px 20px",
            fontSize: "16px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginRight: "10px",
            backgroundColor: "#721c24",
            color: "#fff",
            border: "none",
            cursor: "pointer",
        },
    };

  

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Ocorreu um erro</h1>
            <p>Houve um problema ao carregar esta página. Por favor, tente as opções abaixo:</p>
            <div className='btnsError'>
                <button style={styles.button} onClick={() => window.location.reload()}>
                    Atualizar Página
                </button>
                <button style={styles.button} onClick={() => (window.location.href = "/")}>
                    Voltar ao Menu Principal
                </button>
            </div>
            <p>Se o erro persistir, entre em contato com o suporte.</p>
        </div>
    );
}
