import './cadastroDeAnuncio.css'

import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { Navigate } from 'react-router-dom';
import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, Progress, Alert
} from 'reactstrap';


//com alert cadastr ode produtos
import CompALert from '../../components/CompAlert/CompAlert';
import { UserContext } from '../../context/UserContext';
import { useLocation, useNavigate } from 'react-router';

//props alerta info cadastro
import SuccessEnvioDados from '../../util/SuccessEnvioDados'

import moment from 'moment/moment';

//função HeadersGet
import { HeadersGet } from '../../util/headers'

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../util/tools'

//importar função fetch
import { SERVER, UrlServer } from '../../util/conect'


const animatedComponents = makeAnimated();



export default function EditarAnuncio({ setModal, modal }) {

    const { state: { idVendas } } = useLocation();

    //context
    const { setDadosAPI } = useContext(UserContext)
    //carregando dados recebido  
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);
    //enviar dados para o servidor
    const conServer = async (dadosParaEnvio, metodo) => {


       
        await SERVER({
            rota: 'editar-venda',
            metodo,
            body: dadosParaEnvio,
            id: dadosParaEnvio._id,
            setResponseContext: setDadosAPI,
            chaveContext: 'buscarVendaAnuncios',
            setLoad: setLoad,
            setResponseApi: setResponseApi,
            openAlert: onDismiss

        })
    }

    //fechar modal apos a resposta 
    useEffect(() => {

        if (modal && responseApi.error === false && responseApi.response) {
            setModal(false)
        }
    }, [responseApi])


    const [rSelected, setRSelected] = useState();


    const irPara = useNavigate()

    //com alert cadastar  alerta
    const [visibleCompAlert, setVisibleCompAlert] = useState({ open: false, msg: '' });
    //visibleCompAlert2
    const [visibleCompAlert2, setVisibleCompAlert2] = useState(false);
    const [msgAlert, setMsgAlert] = useState();


    //todos os dados do Form
    const [getContrato, setContrato] = useState({ contratacao: Number(idVendas.contratacao) });


    //setContratoEnvio
    const [ContratoEnvio, setContratoEnvio] = useState();

    //gerar intervalo de data do contrato
    const [FimContrato, setFimContrato] = useState('');
    const [nFimContrato, nsetFimContrato] = useState('');

    //gerar intervalo de data  devinculacao
    const [fim_Vinculacao, set_FimVinculacao] = useState('');
    const [nfim_Vinculacao, nset_FimVinculacao] = useState('');

    //novo valor possivel edição
    const [nNovoValor, nSetNovoValor] = useState('');

    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })

    //alert cadastre um terminal primeiro
    const [notTerminal, setNotTerminal] = useState(false)

    //get terminal servidor
    const [optionsTerminal, setTerminais] = useState([]);

    //get terminal servidor
    const [optionsPlanos, setPlanos] = useState({});


    //verificar preço do plano
    const [novoAnuncio, setNovoAnuncio] = useState();


    //alerta fim do update alertToggle
    const [alertTg, setAlertTg] = useState(true);



    //gerar intervalo de datas  
    function gerarIntervaloDedatas(dataRecebica, tempoContrato) {

        let tipo = 'months'
        let getTipoPagamento = novoAnuncio?.formPagemnto.split('-')[0] || 'months'
        let calcularTempo = 1



        switch (getTipoPagamento) {

            case 'DIÁRIO':

                tipo = 'days'
                calcularTempo = tempoContrato

                break;
            case 'MENSAL':

                tipo = 'months'
                calcularTempo = tempoContrato

                break;
            case 'TRIMESTRAL':

                tipo = 'months'
                calcularTempo = tempoContrato * 3

                break;
            case 'ANUAL':

                tipo = 'years'
                calcularTempo = tempoContrato

                break;
            default:
                break;
        }



        return moment(dataRecebica || moment())
            .add(Number(calcularTempo) || 0, tipo)
            .format("DD/MM/YYYY");

    }

    //gerar datas das  faturas
    function gerarDatasFatura() {

        let quantidadefaturas = []


        function calcularDatas(tempo, tipo) {



            for (let x = 0; x < tempo; x++) {

                switch (tipo) {

                    case 'DIÁRIO':
                        quantidadefaturas.push(
                            {
                                data: gerarIntervaloDedatas(getContrato.inicioVinculacao, x),
                                pago: false
                            }
                        )
                        break;
                    case 'MENSAL':
                        quantidadefaturas.push(
                            {
                                data: gerarIntervaloDedatas(getContrato.inicioVinculacao, x),
                                pago: false
                            }
                        )

                        break;
                    case 'TRIMESTRAL':

                            quantidadefaturas.push(
                                {
                                    data: gerarIntervaloDedatas(getContrato.inicioVinculacao, x),
                                    pago: false
                                }
                            )
                        

                        break;
                    case 'ANUAL':

                       
                            quantidadefaturas.push(
                                {
                                    data: gerarIntervaloDedatas(getContrato.inicioVinculacao, x),
                                    pago: false
                                }
                            )
                        

                        break;
                    default:
                        break;
                }

            }



        }

        if (optionsPlanos?.valorPlano) {



            switch (optionsPlanos?.valorPlano[0].formPagemnto) {


                case 'DIÁRIO':
                    calcularDatas(getContrato.contratacao, 'DIÁRIO')
                    break;
                case 'MENSAL':
                    calcularDatas(getContrato.contratacao, 'MENSAL')
                    break;
                case 'TRIMESTRAL':
                    calcularDatas(getContrato.contratacao, 'TRIMESTRAL')
                    break;
                case 'ANUAL':
                    calcularDatas(getContrato.contratacao, 'ANUAL')
                    break;
                default:
                    break;
            }


        }

        return quantidadefaturas
    }

    useEffect(() => {

        //gerar intervalo de data de contrato
        setFimContrato(gerarIntervaloDedatas(getContrato?.inicioContrato, getContrato?.contratacao))

        //gerar intervalo de data de vinculação     
        set_FimVinculacao(gerarIntervaloDedatas(getContrato?.inicioVinculacao, getContrato?.contratacao))



    }, [getContrato])


    useEffect(() => {
        fetch(`${UrlServer()}/buscar-terminais/${idVendas.id_cliente}`, HeadersGet())
            .then(response => response.json())
            .then(data => {




                setContrato((body) => {
                    return {
                        ...body,
                        whatsApp: data.dadosCliente.whatsApp
                    }
                })
                setNotTerminal(!data ? false : true)

                let terminais =
                    data.response.map((nome) => {
                        return (
                            {
                                value: `${nome.nomeEmpresa}-${nome._id}`,
                                label: nome.nomeEmpresa
                            })

                    })

                setTerminais(terminais)

            })
            .catch((err) => {

                setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'error',
                        msg: 'Erro ao inserir dados, tente novamente mais tarde.'
                    }
                })
            })




        fetch(`${UrlServer()}/buscar-planos`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                /**************************** */
                const getObj = {};
                data.response.forEach((obj) => {
                    let { nomePlano } = obj

                    if (!getObj[nomePlano]) {
                        getObj[nomePlano] = []

                    }
                    getObj[nomePlano].push(obj)
                });

                const nomePlano = Object.keys(getObj).map((nome) => {
                    return (
                        {
                            value: `${nome}`,
                            label: nome
                        }
                    )
                })


                let infoPlano = data.response.map((nome) => {
                    return (
                        {
                            value: `${nome.infoPlano}-${nome._id}`,
                            label: nome.infoPlano
                        })
                })

                setPlanos({
                    todos: data.response,
                    infoPlano,
                    nomePlano
                })

            })
            .catch((err) => {

                setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'error',
                        msg: 'Erro ao inserir dados, tente novamente mais tarde.'
                    }
                })
            })
    }, [])

    //quantidade: em dia || dias / mês || meses / ano || anos
    function quantidadeNome(diaMesAno) {


        const quabtidadeTempo = novoAnuncio?.contratacao || 0;
        const tipoDedata = diaMesAno?.toUpperCase() || 'MENSAL';

        let dadosRetorno = ''



        switch (tipoDedata) {

            case 'DIÁRIO':

                dadosRetorno = quabtidadeTempo > 1 ? `${quabtidadeTempo} dias` : `1 dia`
                break;
            case 'MENSAL':

                dadosRetorno = quabtidadeTempo > 1 ? `${quabtidadeTempo} meses` : `1 mês`

                break;
            case 'TRIMESTRAL':

                dadosRetorno = quabtidadeTempo > 1 ? `${quabtidadeTempo * 3} meses` : `3 meses`

                break;
            case 'ANUAL':

                dadosRetorno = quabtidadeTempo > 1 ? `${quabtidadeTempo * 12} meses` : `12 meses`

                break;
            default:
                break;
        }

        return dadosRetorno
    }


    if (!notTerminal) {

        return (
            <div id='alert-fetch-sucess' className='sucessoFetch'>
                {/* {<Alert color="danger">
                    Necessário cadastrar um terminal primeiro.
                    <br></br>
                    <Link to="/cadastrar-terminal"> Clique aqui para cadastrar.</Link>

                </Alert>} */}
            </div>
        )
    }

    function alertToggle() {
        setAlertTg((to) => false)
        setContrato(fetchErro.data)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }

    function verificarFetch() {

        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'error') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert color="danger">
                            <code style={{ fontSize: '.8rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>

                )
            } else if (fetchErro.tipo === 'sucesso') {


                return (

                    <div id={alertTg ? 'alert-fetch-sucess' : null} className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                irPara('/listar-anuncios')
                                window.location.reload();
                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>

                    </div>

                )
            }
        }
    }

    //vrificar confirmação
    function CompConfimacao(visibleCompAlert2, setVisibleCompAlert2) {
        return (
            <>



                <div id="comp-alert-confirm">
                    <Alert color="danger" isOpen={visibleCompAlert2} style={{ maxWidth: '300px' }}>

                        {msgAlert.msg}

                        <div id='btn-alert-confirm'>

                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                    setVisibleCompAlert2(false)


                                }}
                            >
                                Não
                            </Button>
                            <Button
                                color="success"
                                size="sm"
                                onClick={() => {

                                    setVisibleCompAlert2(false)



                                    msgAlert.tipo === 'deletar' ?

                                        conServer({ _id: idVendas._id }, 'DELETE')
                                        : conServer({ ...ContratoEnvio, _id: idVendas._id, idPlano: Array.isArray(optionsPlanos?.formPagemnto) && optionsPlanos?.formPagemnto[0]?.idPlano }, 'PATCH')

                                }}
                            >
                                Sim
                            </Button>


                        </div>

                    </Alert>
                </div>
            </>
        )
    }
    return (
        <>
            {false && <Navigate to="/listar-anuncios" replace={true} />}
            <div id='cadastro-anuncio'>

                <Form >

                    <div className="box-shadow">

                        <Row>


                            <Col md={6}>
                                <FormGroup>
                                    <Label for="plano">
                                        Plano:
                                    </Label>
                                    <Select
                                        id='plano'
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Plano não cadastrado'}
                                        options={optionsPlanos.nomePlano}
                                        defaultValue={{ value: idVendas.plano, label: idVendas.plano }}
                                        onChange={(e) => {

                                            let filterPlanos = optionsPlanos.todos.filter(value => e.value.toUpperCase() === value.nomePlano.toUpperCase())
                                           
                                            let mapPlanos = filterPlanos.map(val => {
                                              
                                                return (
                                                    {
                                                        value: `${val.formPagemnto}-${val._id}`,
                                                        label: val.formPagemnto,
                                                        idPlano: val._id
                                                    }
                                                )
                                            })

                                            setPlanos((options) => {
                                                return (
                                                    {
                                                        ...options,
                                                        formPagemnto: mapPlanos,
                                                        valorPlano: '',
                                                        idPlano: mapPlanos.idPlano

                                                    }
                                                )
                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    plano: e.value
                                                }
                                            })

                                        }

                                        }
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="formPagemnto">
                                        Pagamento:
                                    </Label>
                                    <Select
                                        id='formPagemnto'
                                        options={optionsPlanos.formPagemnto}
                                        noOptionsMessage={() => ''}
                                        isSearchable={false}
                                        defaultValue={{ value: idVendas.formPagemnto.split('-')[0], label: idVendas.formPagemnto.split('-')[0] }}
                                        onChange={(e) => {


                                            let filterPlanos = optionsPlanos.todos.filter(value => e.value.split('-')[1] === value._id)

                                            setPlanos((options) => {
                                                return (
                                                    {
                                                        ...options,
                                                        valorPlano: filterPlanos
                                                    }
                                                )
                                            })

                                            setRSelected(e.value.split('-')[0].toLowerCase())
                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    formPagemnto: e.value
                                                }
                                            })

                                        }}

                                    />

                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>

                                    <Label for="valorPlano">
                                        Valor:
                                    </Label>


                                    <Input
                                        id="valorPlano"
                                        name="valorPlano"
                                        type="number"
                                        value={nNovoValor ? nNovoValor : optionsPlanos?.valorPlano ? optionsPlanos.valorPlano[0].valorPlano : idVendas.valor}
                                        disabled={novoAnuncio?.plano && novoAnuncio?.formPagemnto ? false : true}
                                        onChange={(e) => {


                                            nSetNovoValor(e.target.value)
                                        }}
                                    />

                                </FormGroup>
                            </Col>


                        </Row>

                        <FormGroup>





                        </FormGroup>

                    </div>

                    <div className="box-shadow">

                        <Row>



                            <Col md={3}>
                                <FormGroup>
                                    <Label for="contratacao">
                                        Quantidade:
                                        {
                                            ` ${quantidadeNome(rSelected)}`

                                        }

                                    </Label>
                                    <Input
                                        disabled={novoAnuncio?.plano && novoAnuncio?.formPagemnto ? false : true}
                                        id="contratacao"
                                        name="contratacao"
                                        type="number"
                                        defaultValue={idVendas.contratacao || getContrato?.contratacao}
                                        onChange={(e) => {

                                            let arrayContratos = []
                                            for (let x = 0; x < Number(RemoverCaracteres(e.target.value)); x++) {
                                                arrayContratos.push({ chave: x, pago: false })
                                            }

                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    contratacao: RemoverCaracteres(e.target.value),
                                                    tempoDeContrato: arrayContratos
                                                }
                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    contratacao: e.target.value
                                                }
                                            })

                                        }}
                                    />
                                </FormGroup>
                            </Col>





                            <Col md={3}>
                                <FormGroup>
                                    <Label for="inicioContrato">
                                        Início do contrato:
                                    </Label>
                                    <Input
                                        disabled={novoAnuncio?.plano && novoAnuncio?.formPagemnto ? false : true}
                                        id="inicioContrato"
                                        name="inicioContrato"
                                        placeholder="Início do contrato"
                                        type="date"
                                        defaultValue={idVendas.inicioContrato}
                                        onChange={(e) => {


                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    inicioContrato: RemoverCaracteres(e.target.value)
                                                }
                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    inicioContrato: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>

                                    <Label for="fimContrato">
                                        Fim do contrato:
                                    </Label>


                                    <Input
                                        id="fimContrato"
                                        name="fimContrato"
                                        placeholder="Fim do contrato"
                                        disabled={novoAnuncio?.plano && novoAnuncio?.formPagemnto ? false : true}
                                        type="text"
                                        value={nFimContrato ? nFimContrato : FimContrato ? FimContrato : idVendas.FimContrato}
                                        onChange={(e) => {

                                            nsetFimContrato(e.target.value)
                                        }}
                                    />






                                </FormGroup>
                            </Col>


                        </Row>


                    </div>

                    <div className="box-shadow">

                        <Row>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="terminal">
                                        Terminal:
                                    </Label>
                                    {optionsTerminal && <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={optionsTerminal}
                                        noOptionsMessage={() => null}
                                        defaultValue={() => {
                                            return idVendas.terminalOption.map((val) => {

                                                return { label: val.split('-')[0], value: val }
                                            })
                                        }}
                                        onChange={(e) => {

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    terminal: e.value,
                                                    terminalOption: e
                                                }
                                            })
                                        }}
                                    />
                                    }
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="inicioVinculacao">
                                        Início da veiculação:
                                    </Label>
                                    <Input
                                        disabled={novoAnuncio?.plano && novoAnuncio?.formPagemnto ? false : true}
                                        id="inicioVinculacao"
                                        name="inicioVinculacao"
                                        placeholder="Início da veiculação"
                                        type="date"
                                        defaultValue={idVendas.inicioVinculacao}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    inicioVinculacao: e.target.value
                                                }

                                            })

                                            setNovoAnuncio((body) => {
                                                return {
                                                    ...body,
                                                    inicioVinculacao: e.target.value
                                                }
                                            })


                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="fimVinculacao">
                                        Fim da veiculação:
                                    </Label>
                                    <Input
                                        id="fimVinculacao"
                                        name="fimVinculacao"
                                        placeholder="Fim da veiculação"
                                        type="text"
                                        disabled={novoAnuncio?.plano && novoAnuncio?.formPagemnto ? false : true}
                                        value={nfim_Vinculacao ? nfim_Vinculacao : fim_Vinculacao ? fim_Vinculacao : idVendas.fim_Vinculacao}
                                        onChange={(e) => {

                                            nset_FimVinculacao(e.target.value)
                                        }}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>

                    </div>

                    <div className="box-footer">
                        <Card style={{ border: 'none' }}>

                            <CardFooter>
                                <Button disabled={getLoad} color="danger" onClick={() => {



                                    //rota,getContrato,setloadResponse,setsucessoFetch,setaguardeTempo    
                                    setVisibleCompAlert2(true)

                                    setMsgAlert(() => {
                                        return {
                                            tipo: 'deletar',
                                            msg: 'Você tem certeza de que deseja deletar esse anúncio?'
                                        }
                                    })



                                }}>
                                    Excluir
                                </Button>
                                <Button disabled={getLoad} color="success" onClick={() => {




                                    let terminais_selecionados

                                    if (novoAnuncio?.terminalOption) {
                                        terminais_selecionados = novoAnuncio?.terminalOption.map((value) => {
                                            return value.value
                                        })

                                    } else {
                                        terminais_selecionados = idVendas.terminalOption
                                    }

                                    function valorEditado() {

                                        if (nNovoValor) {

                                            return Number(nNovoValor)

                                        } else if (optionsPlanos?.valorPlano) {

                                            return Number(optionsPlanos.valorPlano[0].valorPlano)

                                        } else {
                                            return Number(idVendas.valor)
                                        }



                                    }


                                    function gerarValorfatura() {
                                        const getTempoDeContrato = novoAnuncio?.contratacao ? novoAnuncio.contratacao : 0



                                        const getTipoContratacao = novoAnuncio?.formPagemnto ? novoAnuncio.formPagemnto.split('-')[0] : ''

                                        const valorRecebiDoPlano = valorEditado()

                                        let valorCalculado = 0

                                        switch (getTipoContratacao) {

                                            case 'DIÁRIO':

                                                valorCalculado = valorRecebiDoPlano;

                                                break;
                                            case 'MENSAL':

                                                valorCalculado = valorRecebiDoPlano;

                                                break;
                                            case 'TRIMESTRAL':

                                                valorCalculado = valorRecebiDoPlano * getTempoDeContrato;

                                                break;
                                            case 'ANUAL':

                                                valorCalculado = valorRecebiDoPlano * getTempoDeContrato;

                                                break;
                                            default:
                                                break;
                                        }

                                        return valorCalculado > 0 ? valorCalculado : idVendas.valor

                                    }



                                    const dadosParaEnvio =
                                    {
                                        ...novoAnuncio,
                                        valor: gerarValorfatura(),
                                        FimContrato: FimContrato ? FimContrato : idVendas.FimContrato,
                                        inicioContrato: novoAnuncio?.inicioContrato ? novoAnuncio?.inicioContrato : idVendas.inicioContrato,
                                        inicioVinculacao: novoAnuncio?.inicioVinculacao ? novoAnuncio?.inicioVinculacao : idVendas.inicioVinculacao,
                                        fim_Vinculacao: nfim_Vinculacao ? nfim_Vinculacao : fim_Vinculacao ? fim_Vinculacao : idVendas.fim_Vinculacao,
                                        id_cliente: idVendas.id_cliente,
                                        terminalOption: terminais_selecionados,
                                        nomeEmpresa: idVendas.nomeEmpresa,
                                        status: idVendas.status,
                                        fatura: gerarDatasFatura().length > 0 ? gerarDatasFatura() : idVendas.fatura,
                                        whatsapp: getContrato.whatsApp,
                                        idPlanoPagamento: novoAnuncio?.formPagemnto?.split('-')[1]
                                    }

                                 
                                    setContratoEnvio(dadosParaEnvio)

                                    //rota,getContrato,setloadResponse,setsucessoFetch,setaguardeTempo    
                                    setVisibleCompAlert2(true)

                                    setMsgAlert(() => {
                                        return {
                                            tipo: 'editar',
                                            msg: 'Você tem certeza de que deseja editar esse anúncio?'
                                        }
                                    })




                                }}>
                                    Salvar
                                </Button>



                            </CardFooter>

                        </Card>
                    </div>

                </Form>
                {getLoad && <Progress animated value={100} />}
            </div>

            <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />


            {
                visibleCompAlert.open &&
                <CompALert
                    visibleCompAlert={visibleCompAlert}
                    setVisibleCompAlert={setVisibleCompAlert}
                />
            }
            {fetchErro.btn && verificarFetch()}
            {visibleCompAlert2 && CompConfimacao(visibleCompAlert2, setVisibleCompAlert2)}
        </>
    )
}


