import './listarUsuarios.css';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { ConectURL, UrlServer } from '../../../util/conect';
import { HeadersGet } from '../../../util/headers';
import { Link } from 'react-router-dom';

import { Progress } from 'reactstrap';


export default function Usuarios() {


    const [getUserDate, setUserDate] = useState({})
    const [fetchErro, setfetchErro] = useState({})
    const [loandig, setLoandig] = useState(true)



    useEffect(() => {
        fetch(`${UrlServer()}/buscar-usuario/${JSON.parse(Cookies.get('stk-log')).id}`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.connected) {
                    setUserDate(data)
                } else {
                    setUserDate(data)
                }
            }
            ).catch((e) => console.log(e))
            .finally(() => setLoandig(false))
    }, [])

    useEffect(() => {
        setUserDate({ response: fetchErro.resFull })
        setLoandig(false)
    }, [fetchErro])


    function CadastrarUser() {
        return (
            <div className='btn-cadastrar-user'>
                <span>
                    <Link to='/cadastrar-usuario'>Cadastrar Usuário</Link>
                </span>
            </div>
        )
    }

    function fAtivarDesativar(user) {

        setLoandig(true)

        ConectURL(
            'PATCH',
            'editar-usuario',
            'User editado',
            { empresaAtiva: !user.empresaAtiva },
            setfetchErro,
            user._id
        )
    }



    if (loandig) {
        return <Progress style={{ margin: '0px 20px 0 20px' }} animated value={100} color="warning" />
    }

    if (getUserDate?.response === 'Não autorizado') {
        return <>Não autorizado</>
    }





    return (

        <>

            <CadastrarUser />
            {Array.isArray(getUserDate?.response) && <div id='list-users'>
                <ul>
                    {getUserDate?.response.map((cliente) => (
                        <li className='listar-usuarios' key={cliente._id}>
                            {cliente.nomeEmpresa}
                            <span className='lista-interna'>
                                <span onClick={() => fAtivarDesativar(cliente)} className='btn-user' style={cliente.empresaAtiva ? { background: '#ed6262' } : { background: '#28b860' }}>
                                    {cliente.empresaAtiva ? 'Desativar' : 'Ativar'}</span>
                            </span>
                        </li>

                    ))}
                </ul>
            </div>}

        </>

    )
}