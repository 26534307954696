import React, { useState, useEffect } from 'react';

const Contador = ({ alvo, count }) => {
  const [contador, setContador] = useState(0);

  //Calcular temp odo intervalo
  if (!alvo) {
    alvo = 0
  }

  if (!count) {
    if (alvo <= 15) {
      count = 150
    } else if (alvo > 15 && alvo <= 30) {
      count = 50
    } else if (alvo > 30 && alvo <= 50) {
      count = 25
    } else if (alvo > 50 && alvo <= 100) {
      count = 15
    } else if (alvo > 100 && alvo <= 500) {
      count = 5
    } else {
      count = 0.2
    }
  }


  useEffect(() => {
    if (contador < alvo) {
      const timeout = setTimeout(() => setContador(contador + 1), count);
      return () => clearTimeout(timeout);
    }
  }, [contador, alvo, count]);

  // Função para resetar o contador
  useEffect(() => {
    setContador(0); // Isso reinicia o contador para 0
  }, [alvo]);

  return <div>{contador.toLocaleString('pt-BR')}</div>;
};


export default Contador