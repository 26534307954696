
import './novoAnuncio.css'

import React, { useState } from 'react';

import {
    Modal, ModalHeader, ModalBody

} from 'reactstrap';


//dados do anuciante
import EditarAnuncio from './pageeditarAnuncio';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

//context todos os dados da api


export default function NovoAnuncio() {

    const { state: { idVendas } } = useLocation();
    const irPara = useNavigate()

    const [modal, setModal] = useState(true);
    const toggle = () => {
        setModal(!modal);
        irPara('/listar-anuncios')
    }




    return (
        <>
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>{`Editar anúncio: ${idVendas?.nomeEmpresa}`}
                    
                </ModalHeader>
                <ModalBody>

                    <EditarAnuncio setModal={toggle} modal={modal} />

                </ModalBody>

            </Modal>
        </>
    )
}
