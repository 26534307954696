import { useEffect, useState } from "react"
import { UrlServer } from "../../util/conect"
import Cardproposta from "./cardProposta"

import CircularProgress from '@mui/material/CircularProgress';


import './proposta.css'
import OndeEstamos from "./ondeEstamos";
import NossosNumeros from "./nossosNumeros";
import ContainerBanner from './containerBanner/ContainerBanner'
import TopoProposta from "./topoPorposta/topoProposta";
import Estatisticas from "./estatisticas/estatisticas";
import MarcasQueConfiam from "./marcasQueConfiam/marcasQueConfiam";
import Rodape from "./rodape";
import { useParams } from "react-router-dom";





export default function Proposta({ cliente }) {

    const [getProposta, setProposta] = useState()
    const [dadosEmpresa, setDadosEmpresa] = useState()
    const { id } = useParams();


    useEffect(() => {



        fetch(`${UrlServer()}/proposta-comercial/${id || cliente}`)
            .then(response => response.json())
            .then(data => {




                if (data.response === 'Acesso negado') {
                    setProposta(401)
                } else if (data.response?.dadosEmpresa?.length === 0 || data.response?.todosPlanos.length === 0) {
                    setProposta(400)
                } else {

                  

                    const dadosEmpresa = {
                        cnpjEmpresaProposta: data.response.dadosEmpresa[0].cnpjEmpresaProposta,
                        corSelecionada: data.response.dadosEmpresa[0].corSelecionada,
                        emailProposta: data.response.dadosEmpresa[0].emailProposta,
                        instagram: data.response.dadosEmpresa[0].instagram,
                        nomeEmpresaProposta: data.response.dadosEmpresa[0].nomeEmpresaProposta,
                        tituloPrimario: data.response.dadosEmpresa[0].tituloPrimario,
                        tituloSecundario: data.response.dadosEmpresa[0].tituloSecundario,
                        whatsApp: data.response.dadosEmpresa[0].whatsApp,
                        _id: data.response.dadosEmpresa[0]._id,
                        quantTelas: data.response.dadosEmpresa[0].quantTelas,
                        quantClientes: data.response.dadosEmpresa[0].quantClientes,
                        quantCidade: data.response.dadosEmpresa[0].quantCidade,
                        quantInsercoes: data.response.dadosEmpresa[0].quantInsercoes,

                        videoBg: data.response.dadosEmpresa[0]?.videoBg || 1,
                        habilitarBtn: data.response.dadosEmpresa[0]?.habilitarBtn || false,
                        corBtn: data.response?.dadosEmpresa[0]?.corBtn || data?.response[0]?.corSelecionada,

                        marcasClientes: data.response.dadosEmpresa[0].marcasClientes,
                        marcasClientesDelete: data.response.dadosEmpresa[0].marcasClientesDelete,
                        insercoesMes: data.response.dadosEmpresa[0].insercoesMes,



                        iconeCor: data.response.dadosEmpresa[0].iconeCor || "#515151",
                        iconeCard: data.response.dadosEmpresa[0].iconeCard || "Icon_01",
                        iconeTamanho: data.response.dadosEmpresa[0].iconeTamanho || "4rem",
                        ajusteImgExtra: data.response.dadosEmpresa[0].ajusteImgExtra || "contain",
                        //ajusteImgExtra

                        quemSomos1: data.response.dadosEmpresa[0]?.quemSomos1 || "",
                        quemSomos2: data.response.dadosEmpresa[0]?.quemSomos2 || "",

                        ondeEstamos: data.response.todosTerminais,

                        positionStart: data.response?.positionStart,
                        empresaAtiva: data.response?.empresaAtiva,
                        imagens:  data.response.dadosEmpresa[0]?.imagens,

                    }


                    setDadosEmpresa(dadosEmpresa)
                    setProposta(data.response)

                    //setDadosEmpresa(dadosEmpresa)
                    //setProposta(data.response)

                }

            })
            .catch(() => {
                setProposta('error')
            })
    }, [])






    if (!getProposta) {

        return (
            <div className="load">
                <CircularProgress />
            </div>
        )
    }

    if (getProposta === 'error') {
        return (
            <div className="load">
                Empresa não localizada.
            </div>
        )
    }



    if (!dadosEmpresa) {
        return (
            <>
                <div className="load">Proposta comercial indisponível no momento.</div>
            </>
        )
    }

    if (getProposta === 401) {
        return (
            <>
                <div className="load">Erro 401 Não Autorizado</div>
            </>
        )
    } else if (getProposta === 400) {
        return (
            <>
                <div>Não cadastrado</div>
            </>
        )
    }
    if (getProposta) {



        return (
            <>




                <div id="containerProposta">

                    <TopoProposta dadosEmpresa={dadosEmpresa} videobg={dadosEmpresa?.habilitarBtn} />


                    <ContainerBanner dadosEmpresa={dadosEmpresa} videobg={dadosEmpresa?.habilitarBtn} />

                    <div id="TituloPlano" style={{ color: dadosEmpresa?.corSelecionada }}>
                        Planos
                    </div>

                    <Cardproposta body={getProposta} dadosEmpresa={dadosEmpresa} />

                    <NossosNumeros dadosEmpresa={dadosEmpresa} />

                    <Estatisticas dadosEmpresa={dadosEmpresa} />

                    <MarcasQueConfiam dadosEmpresa={dadosEmpresa} />  {/* Alterei para Quem Somos */}

                    <OndeEstamos dadosEmpresa={dadosEmpresa} />

                    <Rodape dadosEmpresa={dadosEmpresa} />

                </div>
            </>
        )
    }
}