
import './ondeEstamos.css'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import FadeInOnScroll from '../../components/framerMotion/framerMotion';
import RoomSharpIcon from '@mui/icons-material/RoomSharp';
import TvIcon from '@mui/icons-material/Tv';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useState } from 'react';
import MapaEmpresas from './mapa/mapaEmpresas'

export default function OndeEstamos({ dadosEmpresa }) {

    const [modal, setModal] = useState(false);
    const [bodyInfo, setBodyInfo] = useState()

    function hexToRgba(hex, alpha = 1) {
        let r = 0, g = 0, b = 0;


        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }

        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }


        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    const toggle = () => setModal(!modal);


    function PrintBodyModal({ body }) {

        if (!body) return


        return (
            <div className='modal-onde-estamos'>
                <div className=''>{body.descricaoTerminal}</div>

                {body.quantidadeTelas && <div className=''>Este estabelecimento possui <span style={{ fontWeight: '600' }}>{body.quantidadeTelas} {Number(body?.quantidadeTelas) > 1 ? 'telas' : 'tela'}</span> de publicidade para você impulsionar suas vendas. </div>}

                <div style={{ border: '1px solid #cecece' }}></div>
                <div>
                    <div className='horario-funcionamento'>Seu anúncio será exibido nos seguintes dias e horários:</div>
                    {body?.horarioFun.split('\n').map((horario) => (
                        <div key={horario} style={{ marginBottom: '5px' }}>
                            <span style={{ fontSize: '1rem' }}>{horario}</span>
                        </div>
                    ))}
                </div>
                <div style={{ border: '1px solid #cecece' }}></div>
                <div className='modal-obs-terminal'>{body.obsTerminal}</div>
                {body?.urlVidTerminal && <div className='previa-vid btn-instagram' style={{ backgroundColor: hexToRgba(dadosEmpresa?.corSelecionada), color: '#fff' }}>
                    <a target="_blank" rel="noopener noreferrer" href={body?.urlVidTerminal}>Veja uma prévia</a>
                </div>}
            </div>
        )
    }

    return (

        <>

            <div id="containerOndeEstamos">
                <div id="TituloPlano" className="nossosNumeros" style={{ color: dadosEmpresa?.corSelecionada }}>
                    Onde estamos
                </div>

                <div className="bory-onde-estamos">

                    <div className="child2">
                        <MapaEmpresas empresas={dadosEmpresa} />
                    </div>
                    {/* empresa.empresaAtiva */}
                    <div className="BoxOndeEstamos child1">

                        {
                            dadosEmpresa?.ondeEstamos?.map((empresa) => {

                              return  < FadeInOnScroll key={empresa._id} className="fade-empresas" >
                                    <div className='ondeEstamosEmpresas' >
                                        {/* <RoomSharpIcon style={{ color: '#252525', fontSize: '3rem', marginBottom: '10px' }} /> */}
                                        <div className='topo-empresas'>
                                            <span className='rotulo-empresa'>
                                                <TvIcon fontSize='1.2rem' style={{ marginRight: '4px' }} />
                                                {empresa?.quantidadeTelas}   {Number(empresa?.quantidadeTelas) > 1 ? 'telas' : 'tela'}
                                            </span>
                                        </div>

                                        <div className='body-ondeEstamosEmpresas'>
                                            <div className='line-height-1'>
                                                <div className='titulo-nome-empresa fw-600'>{empresa?.nomeEmpresa}</div>
                                                <span className='titulo-bairro'> Bairro: {empresa?.bairroEmpresa}</span>
                                            </div>

                                            <div className='ondeestamosBairro'>
                                                <div className='linha'></div>
                                                <div className='ver-detalhes-terminal' onClick={() => {

                                                    setBodyInfo(() => {
                                                        return {
                                                            body: empresa
                                                        }
                                                    })
                                                    toggle()
                                                }}>Ver detalhes</div>

                                            </div>
                                        </div>

                                    </div>
                                </FadeInOnScroll>


                            })
                        }

                    </div>

                </div>


            </div >


            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>{bodyInfo?.body?.nomeEmpresa}</ModalHeader>
                <ModalBody>
                    <PrintBodyModal body={bodyInfo?.body} />
                </ModalBody>
            </Modal>
        </>
    )
}