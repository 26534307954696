import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Corrige problema do ícone quebrado
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const customIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [15, 25],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [25, 25],
});

const MapaEmpresas = ({ empresas }) => {
  const [empresasGeo, setEmpresasGeo] = useState({
    lat: empresas.positionStart.lat,
    lon: empresas.positionStart.lon,
  });
  const [isMovable, setIsMovable] = useState(false);



  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setEmpresasGeo({ lat: position.coords.latitude, lon: position.coords.longitude });
        },
        (error) => {
          alert("Ative a localização no seu dispositivo:", error.message);
        }
      );
    } else {
     alert("Geolocalização não é suportada neste navegador.");
    }
  }, []);

  useEffect(() => {

  }, [isMovable])

  const ButtonMap = () => {
    return (
      <button
        onClick={() => setIsMovable((prev) => !prev)}
        style={{
          position: "absolute",
          bottom: "5px",
          left: "10px",
          zIndex: 1000,
          background: "white",
          border: "1px solid #ccc",
          padding: "5px 10px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        {isMovable ? "Bloquear Mapa" : "Mover Mapa"}
      </button>
    )
  }

  const BodyMapa = ({ open }) => {
    return (
      <div style={{ position: "relative" }}>
        <MapContainer
          dragging={open}
          touchZoom={open}
          doubleClickZoom={open}
          scrollWheelZoom={open}
          center={[empresasGeo?.lat || -10.3333, empresasGeo?.lon || -53.2]}
          zoom={empresasGeo?.lon ? 13 : 4}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {empresas.ondeEstamos.map((empresa, index) =>
            empresa?.lat && empresa?.empresaAtiva &&(
              <Marker key={index} position={[empresa.lat, empresa.lon]} icon={customIcon}>
                <Popup>{empresa.nomeEmpresa}</Popup>
              </Marker>
            ) 
          )}
        </MapContainer>


        <ButtonMap />


      </div>
    )
  }



  return (
    isMovable ? <BodyMapa open={isMovable} /> : <BodyMapa open={isMovable} />
  );
};

export default MapaEmpresas;
