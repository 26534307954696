import React, { useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom'
import {
    Button, Input, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, Progress, Alert,

} from 'reactstrap';
//context todos os dados da api
import { UserContext } from '../../../context/UserContext';
//importar função fetch
import { SERVER, ConectURL } from '../../../util/conect'
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'
//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'
import MapComponent from '../mapa/MapComponent';

export default function Cliente({ dados, vendas, setModal, modal }) {

    //context
    const { setDadosAPI } = useContext(UserContext)
    //carregando dados recebido  
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);
    //enviar dados para o servidor
    const conServer = async (dadosParaEnvio, metodo) => {
        await SERVER({
            rota: 'editar-terminal',
            metodo,
            body: dadosParaEnvio,
            id: dadosParaEnvio._id,
            setResponseContext: setDadosAPI,
            chaveContext: 'buscarTerminaisTerminal',
            setLoad: setLoad,
            setResponseApi: setResponseApi,
            openAlert: onDismiss

        })
    }

    //confirmação alerta info
    const [visibleCompAlert, setVisibleCompAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState();

    const [openMap, setOpenMap] = useState();

    const toogleMap = () => {
        setOpenMap(value => !value)
    }

    //todos os dados do Form
    const [getContrato, setContrato] = useState(
        { ...dados }
    );


    const [empresasGeo, setEmpresasGeo] = useState({ lat: 0, lon: 0 });
    // pegar localização se não tiver nenhum dado de latidude informado pelo cliente
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setEmpresasGeo({ lat: position.coords.latitude, lon: position.coords.longitude });
                },
                (error) => {
                    alert("Ative a localização no seu dispositivo:", error.message);
                    setEmpresasGeo({ lat: 0, lon: 0 });
                }
            );
        } else {
            alert("Geolocalização não é suportada neste navegador.");
            setEmpresasGeo({ lat: 0, lon: 0 });
        }
    }, []);


    //fechar modal apos a resposta 
    useEffect(() => {
       
        if (modal && responseApi.error === false && responseApi.response) {
            setModal(false)
        }
    }, [responseApi])

    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })


    //alerta fim do update alertToggle
    const [alertTg, setAlertTg] = useState(true);

    const navegacao = useNavigate()

    function alertToggle() {
        setAlertTg((to) => !to)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }

    function verificarFetch() {




        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'error') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert color="danger" toggle={alertToggle} style={{ minWidth: '300px', minHeight: '50px' }}>
                            <code style={{ fontSize: '.8rem', minWidth: '300px', minHeight: '50px' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>

                )
            } else if (fetchErro.tipo === 'sucesso') {



                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                navegacao('/listar-terminais')
                                window.location.reload()

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>
                    </div>

                )
            }
        }
    }


    //vrificar confirmação
    function CompConfimacao(visibleCompAlert, setVisibleCompAlert) {
        return (
            <>
                <div id="comp-alert-confirm">
                    <Alert color="danger" isOpen={visibleCompAlert} style={{ maxWidth: '300px' }}>

                        {msgAlert.msg}

                        <div id='btn-alert-confirm'>

                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                    setVisibleCompAlert(false)


                                }}
                            >
                                Não
                            </Button>
                            <Button
                                color="success"
                                size="sm"
                                onClick={() => {

                                    setVisibleCompAlert(false)


                                    msgAlert.tipo === 'deletar' ?
                                        conServer(getContrato, 'DELETE')
                                        : conServer(getContrato, 'PATCH')

                                }}>
                                Sim
                            </Button>


                        </div>

                    </Alert>
                </div>
            </>
        )
    }

    //verificar se o terminal tem anuncio
    function verificarTerminal() {

        let idCliente = []
        vendas.forEach(element => {

            element.terminalOption.forEach((val) => {
                idCliente.push(val.split('-')[1])
            })


        });

        return !idCliente.includes(dados._id)
    }



    return (


        <div id='anunciante-body' >


            <div className="box-shadow">

                <Form id='dadosDaEmpresa'>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="nomeEmpresa">
                                    Nome:
                                </Label>
                                <Input
                                    id="nomeEmpresa"
                                    name="nomeEmpresa"
                                    placeholder="Nome da empresa"
                                    type="text"
                                    defaultValue={getContrato.nomeEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                nomeEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="responsavelEmpresa">
                                    Responsável:
                                </Label>

                                <Input
                                    id="responsavelEmpresa"
                                    name="responsavelEmpresa"
                                    placeholder="Nome do Responsável"
                                    type="text"
                                    defaultValue={getContrato.responsavelEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                responsavelEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="documentoEmpresa">
                                    CPF / CNPJ:
                                </Label>
                                <Input
                                    id="documentoEmpresa"
                                    name="documentoEmpresa"
                                    type="number"
                                    defaultValue={getContrato.documentoEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                documentoEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="whatsApp">
                                    WhatsApp:
                                </Label>
                                <Input
                                    type="number"
                                    id="whatsApp"
                                    name="whatsApp"
                                    defaultValue={getContrato.whatsApp}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                whatsApp: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="enderecoEmpresa">
                                        Endereço:
                                    </Label>
                                    <Input
                                        id="enderecoEmpresa"
                                        name="enderecoEmpresa"
                                        type="text"
                                        defaultValue={getContrato.enderecoEmpresa}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    enderecoEmpresa: RemoverCaracteres(e.target.value)
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="bairroEmpresa">
                                        Bairro:
                                    </Label>
                                    <Input
                                        id="bairroEmpresa"
                                        name="bairroEmpresa"
                                        type="text"
                                        defaultValue={getContrato.bairroEmpresa}
                                        onChange={(e) => {
                                            setContrato((body) => {
                                                return {
                                                    ...body,
                                                    bairroEmpresa: RemoverCaracteres(e.target.value)
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Row>


                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cidadeEmpresa">
                                    Cidade:
                                </Label>
                                <Input
                                    id="cidadeEmpresa"
                                    name="cidadeEmpresa"
                                    defaultValue={getContrato.cidadeEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                cidadeEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="estadoEmpresa">
                                    Estado:
                                </Label>
                                <Input
                                    id="estadoEmpresa"
                                    name="estadoEmpresa"
                                    defaultValue={getContrato.estadoEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                estadoEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="cepEmpresa">
                                    CEP:
                                </Label>
                                <Input
                                    type='number'
                                    id="cepEmpresa"
                                    name="cepEmpresa"
                                    defaultValue={getContrato.cepEmpresa}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                cepEmpresa: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="inicioContrato">
                                    Início do contrato:
                                </Label>
                                <Input
                                    type="date"
                                    id="inicioContrato"
                                    name="inicioContrato"
                                    defaultValue={getContrato.inicioContrato}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                inicioContrato: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="FimContrato">
                                    Fim do contrato:
                                </Label>
                                <Input
                                    type='date'
                                    id="FimContrato"
                                    name="FimContrato"
                                    defaultValue={getContrato.FimContrato}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                FimContrato: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                    </Row>




                    <div style={{ borderTop: '2px solid #c3c2c2', margin: '10px 0' }}></div>


                    <Row>
                        <div style={{ fontSize: '1.5rem', fontWeight: '600', }}>
                            Dados do terminal na proposta comercial
                        </div>
                    </Row>

                    <div className="corecao-ma" style={{ marginBottom: '15px' }}>
                        Se o marcador de localização na proposta comercial estiver incorreto, <span style={{ fontWeight: '600', cursor: 'pointer' }} onClick={() => { toogleMap() }
                        }>clique aqui</span> para corrigir.
                    </div>

                    {openMap && <div className="mapa-empresa">
                        <MapComponent empresa={getContrato.lat ? getContrato : empresasGeo} setContrato={setContrato} height={"500px"} />
                    </div>}

                    <Row>

                        <Col md={2}>
                            <FormGroup>
                                <Label for="fluxoMedio">
                                    Fluxo de pessoas:
                                </Label>
                                <Input
                                    type='number'
                                    id="fluxoMedio"
                                    name="fluxoMedio"
                                    defaultValue={getContrato.fluxoMedio}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                fluxoMedio: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="quantidadeTelas">
                                    Quant. de telas:
                                </Label>
                                <Input
                                    type='number'
                                    id="quantidadeTelas"
                                    name="quantidadeTelas"
                                    defaultValue={getContrato?.quantidadeTelas}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                quantidadeTelas: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="urlVidTerminal">
                                    Link vídeo / instagram terminal:
                                </Label>
                                <Input
                                    type='text'
                                    id="urlVidTerminal"
                                    name="urlVidTerminal"
                                    defaultValue={getContrato?.urlVidTerminal}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                urlVidTerminal: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>

                    </Row>



                    <Row>

                        <Col md={4}>
                            <FormGroup>
                                <Label for="descricaoTerminal">
                                    Descrição:
                                </Label>
                                <Input
                                    type='textarea'
                                    rows="5"
                                    id="descricaoTerminal"
                                    name="descricaoTerminal"
                                    defaultValue={getContrato?.descricaoTerminal}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                descricaoTerminal: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>


                        <Col md={4}>
                            <FormGroup>
                                <Label for="horarioFun">
                                    Horário de funcionamento:
                                </Label>
                                <Input
                                    type='textarea'
                                    rows="5"
                                    id="horarioFun"
                                    name="horarioFun"
                                    defaultValue={getContrato.horarioFun}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                horarioFun: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label for="obsTerminal">
                                    Observações:
                                </Label>
                                <Input
                                    type='textarea'
                                    rows="5"
                                    id="obsTerminal"
                                    name="obsTerminal"
                                    defaultValue={getContrato.obsTerminal}
                                    onChange={(e) => {
                                        setContrato((body) => {
                                            return {
                                                ...body,
                                                obsTerminal: RemoverCaracteres(e.target.value)
                                            }
                                        })
                                    }}

                                />
                            </FormGroup>
                        </Col>
                    </Row>



                    <FormGroup check>

                        <Input
                            id="empresaAtiva"
                            name="empresaAtiva"
                            type="checkbox"
                            defaultChecked={getContrato.empresaAtiva}
                            onChange={(e) => {
                                setContrato((body) => {
                                    return {
                                        ...body,
                                        empresaAtiva: e.target.checked
                                    }
                                })
                            }}
                        />
                        <Label
                            check
                            for="empresaAtiva"

                        >
                            Empresa ativa
                        </Label>
                    </FormGroup>

                </Form>
            </div>

            <div className="box-footer">
                <Card style={{ border: 'none' }}>
                    <CardFooter>

                        {verificarTerminal() && < Button color="danger" onClick={() => {

                            setVisibleCompAlert(true)
                            setMsgAlert(() => {
                                return {
                                    tipo: 'deletar',
                                    msg: 'Você tem certeza de que deseja deletar esse cliente?'
                                }
                            })


                        }}>
                            Deletar
                        </Button>}


                        <Button disabled={getLoad} color="primary" onClick={() => {

                            setVisibleCompAlert(true)
                            setMsgAlert(() => {
                                return {
                                    tipo: 'editar',
                                    msg: 'Você tem certeza de que deseja editar esse terminal?'
                                }
                            })

                        }}>
                            Editar
                        </Button>

                    </CardFooter>
                </Card>
            </div>
            {getLoad && <Progress animated value={100} />}
            <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />



            {fetchErro.btn && verificarFetch()}
            {visibleCompAlert && CompConfimacao(visibleCompAlert, setVisibleCompAlert)}

        </div >
    )
}


