




export default function BoxConfig({ icone, titulo, texto, toggle, setTipo, newPage }) {

    function irParaUsuario() {
        if (newPage) return
    }



    return (
        <>
            <div className="container-conf" onClick={() => {

                irParaUsuario()
                toggle()
                setTipo(titulo)

            }}>
                <div className="icone-config">
                    {icone}
                </div>
                <div className="texto-conf">
                    <div className="titulo-um">{titulo}</div>
                    <div className="texto-conf-info">{texto}</div>
                </div>
            </div>
        </>
    )
}