import './page.css'


import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import { Button } from 'reactstrap';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TvIcon from '@mui/icons-material/Tv';
import { Input, Row, Col, FormGroup, Label, } from 'reactstrap'
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import PropostaPadrao from './planos/CompProposta'
import { PrecoBrl } from '../../../util/precoBrl';

const GeneratePDF = ({ conf, getDadosPdfproposta, SalvarDados, getLoad, getTrocarPlanos, setTrocarPlanos, optionsTerminal }) => {




    const animatedComponents = makeAnimated();

    const { todosPlanos, locaisOndeEstamos } = getDadosPdfproposta?.response
    const [propostaPersonalizada, setPropostaPersonalizada] = useState({
        investimento: '',
        periodo: '',
        quantidadeLocais: optionsTerminal?.length,
        locaisproposta: optionsTerminal?.map(empresa => empresa.label).join(', ')
    })





    const formatNumber = (num) => {

        return num >= 1000 ? `+${Math.floor(num / 1000)} mil*` : `+${num} *`;

    };







    const generatePDF = () => {



        const doc = new jsPDF('p', 'mm', 'a4');
        const elementHTML = document.getElementById("content-to-pdf"); // O ID do HTML a ser convertido


        // Adiciona o conteúdo HTML no PDF
        doc.html(elementHTML, {
            callback: function (doc) {

                doc.save(`${conf?.nomeEmpresa ? String(conf?.nomeEmpresa).replaceAll(' ', '-') : 'Proposta'}`);

            },
            margin: [0, 0, 0, 0],
            x: 3,
            y: -2,
            html2canvas: {
                scale: 0.25,
                useCORS: true,
                allowTaint: true
            }

        });



    };



    const ProcessarLocais = () => {


        return (
            <>
                {locaisOndeEstamos.map((locais, ind) => (
                    <div className='dados-locais' key={locais.nome}>

                        <div style={{ color: conf.corSelecionada }} className="pdf-nome-local">{locais.nome}</div>
                        <div style={{ color: conf.corSecundaria }} className="pdf-bairro-local">{locais.bairro}</div>
                    </div>
                ))}
            </>
        )
    }


    function salvarDados() {


        SalvarDados('PDF-Proposta')
    }

    function processarAltura() {

        const altura = locaisOndeEstamos.length

        if (altura < 7) {
            return { altura: '100px', margem: '0px' }
        } else if (altura < 13) {
            return { altura: '200px', margem: '0px' }
        } else if (altura < 20) {
            return { altura: '250px', margem: '0px' }
        } else if (altura < 27) {
            return { altura: '300px', margem: '90px' }
        } else if (altura >= 27) {
            return { altura: '400px', margem: '90px' }
        }


    }



    function PropostaPersonalizada() {
        return (
            <div className="info-investimento-pdf  ml mr">
                <div className="investimento-valor">

                    <div className='section-investimento'>
                        <div style={{ color: conf.corSelecionada, }}> {`Investimento: ${PrecoBrl(propostaPersonalizada?.investimento)}`}</div>

                    </div>

                    <div className='section-investimento'>
                        <div style={{ color: conf.corSelecionada, marginRight: '5px' }}>Périodo: </div>
                        <div>{propostaPersonalizada.periodo} </div>
                    </div>

                </div>

                <div className="investimento-locais">
                    <span style={{ color: conf.corSelecionada, }} className='pdf-locais'>{`${propostaPersonalizada.quantidadeLocais} locais`}</span>
                    <span style={{ lineHeight: '1.2' }}>{propostaPersonalizada.locaisproposta}</span>
                </div>

                <div style={{ fontWeight: '600', marginTop: '8px' }}>
                    {propostaPersonalizada.obsProposta}
                </div>
            </div>
        )
    }

    function trocarPlanos() {
        setTrocarPlanos(e => !e)

    }


    return (
        <div>

            {!getTrocarPlanos && <div className='pdfPersonaliadoPro'>
                <Row>
                    <Col md={16}>
                        <Label htmlFor='investimento' >Investimento:</Label>
                        <Input type='number' id='investimento' onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        investimento: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>
                <Row>
                    <Col md={16}>
                        <Label htmlFor='periodo' >Período:</Label>
                        <Input id='periodo' onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        periodo: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>
                <Col style={{ marginTop: '10px' }} md={12}>
                    <FormGroup>
                        <Label for="locaisproposta">
                            Terminal:
                        </Label>
                        {optionsTerminal && <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={optionsTerminal}
                            placeholder='Digite o terminal'
                            noOptionsMessage={() => null}
                            defaultValue={optionsTerminal}
                            onChange={(e) => {

                                setPropostaPersonalizada((body) => {

                                    return {
                                        ...body,
                                        quantidadeLocais: e?.length,
                                        locaisproposta: e?.map(empresa => empresa.label).join(', ')

                                    }
                                })


                            }}
                        />
                        }
                    </FormGroup>
                </Col>
                <Row style={{ marginTop: '10px' }}>
                    <Col md={12}>
                        <Label htmlFor='obsProposta' >Observações:</Label>
                        <Input type="textarea" rows="3" id='obsProposta' maxLength={85} onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        obsProposta: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>
            </div>}

            <div id="content-to-pdf">
                <div className="topo-pdf">
                    <div className="logo-pdf ml"><img src={conf?.logo} alt={conf.nomeEmresa} width={150} /></div>
                    <div style={{ background: conf.corSelecionada }} className="elemento-rentagulo-pdf"></div>
                </div>
                <div className="texto-pdf">
                    <div style={{ color: conf.corSelecionada }} className="titulo-linha-um ml mr">{conf.textoTitulo} </div>
                </div>
                <div className="estatisticas-pdf ml mr " style={{ background: conf.corPrimariaBg }}>
                    <div className="pdf-container">
                        <div className="pdf-column">
                            <div className="pdf-icone cinza-claro-pdf"><PeopleIcon /></div>
                            <div style={{ color: conf.corSelecionada }} className="pdf-titulo-box">Pessoas/dia</div>
                            <div className="pdf-info-box cinza-claro-pdf">{formatNumber(conf.fluxoMedio)}</div>
                        </div>

                        <div className="pdf-separator"></div>
                        <div className="pdf-column ">
                            <div className="pdf-icone cinza-claro-pdf"><CalendarMonthIcon /></div>
                            <div style={{ color: conf.corSelecionada }} className="pdf-titulo-box">Exibições/mês</div>
                            <div className="pdf-info-box cinza-claro-pdf">{formatNumber(conf.exibicoesMes)}</div>
                        </div>
                        <div className="pdf-separator"></div>
                        <div className="pdf-column">
                            <div className="pdf-icone cinza-claro-pdf"><TvIcon /></div>
                            <div style={{ color: conf.corSelecionada }} className="pdf-titulo-box">Telas</div>
                            <div className="pdf-info-box cinza-claro-pdf">{conf.telas}</div>
                        </div>
                    </div>
                </div>

                <div style={{ color: conf.corSelecionada }} className="titulo-central">{conf.tituloOndeEstamos}</div>
                <div className="section-foto-estatistic ml mr " style={{ background: conf.corPrimariaBg, padding: '10px' }}>
                    <div className="nome-locais" style={{ maxHeight: processarAltura().altura }}>
                        <ProcessarLocais />
                    </div>
                </div>


                {/* Destaque sua marca */}
                <div className="section-foto-estatistic fotos-pdf mtop ml mr h200px" style={{ background: conf.corPrimariaBg }}>

                    <div className="texto-secundario">
                        <div style={{ color: conf.corSelecionada }} className="titulo-rodape-pdf">{conf.tituloTextoRodape}</div>
                        <div className="texto-rodape-pdf cinza-escuro-pdf">{conf.textoRodape}</div>
                    </div>

                    <div className="foto-flex ">


                        <div className="conteudo-fotos-pdf">

                            {
                                conf?.imagensQuemSomos ? conf?.imagensQuemSomos.map((img, ind) => (
                                    <img key={img} style={{ objectFit: 'cover', width: '170px', borderRadius: ind === 1 ? '0 8px 8px 0px' : '' }} src={img} alt={conf.nomeEmresa} height={200} />
                                ))
                                    : (
                                        <>
                                            <img style={{ objectFit: 'cover', width: '170px' }} src='https://i.imgur.com/mBPuTIT.jpeg' alt={conf.nomeEmresa} height={200} />
                                            <img style={{ objectFit: 'cover', width: '170px', borderRadius: '0 8px 8px 0px' }} src='https://i.imgur.com/boc5eVC.jpeg' alt={conf.nomeEmresa} height={200} />
                                        </>
                                    )
                            }

                        </div>
                    </div>
                </div>

                <div className="pdf-separator-horizontal ml mr"></div>
                {
                    getTrocarPlanos ?
                        <PropostaPadrao conf={conf} todosPlanos={todosPlanos} margem={processarAltura().margem} />
                        :
                        <PropostaPersonalizada />
                }
            </div>



            <div className="btn-footer-pdf corPrimariaBg">
                <Button disabled={getLoad} color="secondary" onClick={trocarPlanos} >{getTrocarPlanos ? 'Proposta personalizada' : 'Planos padrão'}</Button>
                <Button disabled={getLoad} color="primary" onClick={generatePDF} >Gerar PDF</Button>
                <Button disabled={getLoad} color="success" onClick={salvarDados} >Salvar</Button>
            </div>
        </div>
    );
};


export default GeneratePDF;



