import './cadastrarTerminal.css'
import '../../../style/pageDentroModal.css'

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Button, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, ModalBody,
    Modal, ModalHeader, Progress, FormFeedback
} from 'reactstrap';

//importar função fetch
import { SERVER, ConectURLPost } from '../../../util/conect'
import MapComponent from '../mapa/MapComponent';

//props alerta info cadastro
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'
//context todos os dados da api
import { UserContext } from '../../../context/UserContext';


//validação de form
import { useForm } from 'react-hook-form';


//FormInpu paar validar dados
import FormInput from '../../../util/validarInput'



export default function CadastrarTerminal() {

    //context
    const { setDadosAPI } = useContext(UserContext)
    //carregando dados recebido  
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);
    //enviar dados para o servidor
    const salvarNovoAnuncio = async (dadosParaEnvio) => {
        await SERVER({
            rota: 'cadastrar-terminal',
            metodo: 'POST',
            body: dadosParaEnvio,
            setResponseContext: setDadosAPI,
            chaveContext: 'buscarTerminaisTerminal',
            setLoad: setLoad,
            setResponseApi: setResponseApi,
            openAlert: onDismiss

        })
    }

    //validação de form 
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [aguardeTempo, setaguardeTempo] = useState(false);


    //Geo empresa
    const [empresasGeo, setEmpresasGeo] = useState();
    const [getDataInput, setFetDataInput] = useState(); //pegar dados do inpur de endereço
    const [fGeo, setFetData] = useState();
    const [responseGeo, setResponseGeo] = useState();
    //debouce fetch    
    const [debounceQuery, setDebounceQuery] = useState("");


    //carregando dados recebido do fetch
    const [errorResponse, setErrrorResponse] = useState(false)
    const [loadResponse, setloadResponse] = useState(false)
    const [sucessoFetch, setsucessoFetch] = useState(false)


    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceQuery(getDataInput);
        }, 500);

        return () => clearTimeout(handler);
    }, [getDataInput]);


    useEffect(() => {
        if (!debounceQuery) return;




        const fetchData = async () => {

            const { enderecoEmpresa, cidadeEmpresa, estadoEmpresa, bairroEmpresa, cepEmpresa } = getDataInput


            if (enderecoEmpresa && cidadeEmpresa && estadoEmpresa && bairroEmpresa && cepEmpresa) {

                ConectURLPost(
                    'buscar-geo',
                    { enderecoEmpresa, cidadeEmpresa, estadoEmpresa, bairroEmpresa, cepEmpresa },
                    setFetData,
                    setFetData,
                    setFetData,
                    setFetData,
                    setResponseGeo,
                    setEmpresasGeo //resposta da geolocalização
                )


            }





        };

        fetchData();
    }, [debounceQuery]);

    //fim debbouce


    // pegar localização se não tiver nenhum dado de latidude informado pelo cliente
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setEmpresasGeo({ lat: position.coords.latitude, lon: position.coords.longitude });
                },
                (error) => {
                    alert("Ative a localização no seu dispositivo:", error.message);
                    setEmpresasGeo({ lat: 0, lon: 0 });
                }
            );
        } else {
            alert("Geolocalização não é suportada neste navegador.");
            setEmpresasGeo({ lat: 0, lon: 0 });
        }
    }, []);


    return (

        <>

            <Modal isOpen={true} fullscreen>

                <ModalHeader id='modalTopo'>

                    <div>Cadastro de terminal</div>
                    <Link className='btn-close' to='/listar-terminais'></Link>
                </ModalHeader>

                <ModalBody>

                    <div id='cadastrar-cliente'>


                        <div className="box-shadow">

                            <Form id='dadosDaEmpresa'>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="nomeEmpresa">
                                                Nome da Empresa:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.nomeEmpresa ? true : false}
                                                type="text"
                                                name="nomeEmpresa"
                                                register={register}

                                                id="nomeEmpresa"
                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>


                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="responsavelEmpresa">
                                                Responsável:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.responsavelEmpresa ? true : false}
                                                type="text"
                                                name="responsavelEmpresa"
                                                id="responsavelEmpresa"
                                                register={register}


                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="documentoEmpresa">
                                                CPF / CNPJ:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.documentoEmpresa ? true : false}
                                                type="number"
                                                name="documentoEmpresa"
                                                id="documentoEmpresa"
                                                register={register}


                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="whatsApp">
                                                WhatsApp:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.whatsApp ? true : false}
                                                type="number"
                                                name="whatsApp"
                                                id="whatsApp"
                                                register={register}

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col >
                                        <FormGroup>
                                            <Label for="enderecoEmpresa">
                                                Endereço:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.enderecoEmpresa ? true : false}
                                                type="text"
                                                name="enderecoEmpresa"
                                                id="enderecoEmpresa"
                                                register={register}
                                                onchange={setFetDataInput}
                                                getGeo={true}

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>

                                    <Col >
                                        <FormGroup>
                                            <Label for="bairroEmpresa">
                                                Bairro:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.bairroEmpresa ? true : false}
                                                type="text"
                                                name="bairroEmpresa"
                                                id="bairroEmpresa"
                                                register={register}
                                                onchange={setFetDataInput}
                                                getGeo={true}

                                            />
                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cidadeEmpresa">
                                                Cidade:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.cidadeEmpresa ? true : false}
                                                type="text"
                                                name="cidadeEmpresa"
                                                id="cidadeEmpresa"
                                                register={register}
                                                onchange={setFetDataInput}
                                                getGeo={true}
                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="estadoEmpresa">
                                                Estado:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.estadoEmpresa ? true : false}
                                                type="text"
                                                name="estadoEmpresa"
                                                id="estadoEmpresa"
                                                register={register}
                                                onchange={setFetDataInput}
                                                getGeo={true}
                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="cepEmpresa">
                                                CEP:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.cepEmpresa ? true : false}
                                                type="number"
                                                name="cepEmpresa"
                                                id="cepEmpresa"
                                                register={register}
                                                onchange={setFetDataInput}
                                                getGeo={true}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>





                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="inicioContrato">
                                                Início contrato:
                                            </Label>

                                            <FormInput
                                                invalid={false}
                                                type="date"
                                                name="inicioContrato"
                                                id="inicioContrato"
                                                register={register}

                                            />



                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="FimContrato">
                                                Fim contrato:
                                            </Label>

                                            <FormInput
                                                invalid={false}
                                                type="date"
                                                name="FimContrato"
                                                id="FimContrato"
                                                register={register}

                                            />



                                        </FormGroup>
                                    </Col>
                                </Row>

                                {empresasGeo && <MapComponent empresa={empresasGeo} setContrato={setEmpresasGeo} height={'300px'} />}

                                <div style={{ borderTop: '2px solid #c3c2c2', marginBottom: '10px' }}></div>

                                <Row>
                                    <div style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>
                                        Dados do terminal na proposta comercial
                                    </div>
                                </Row>

                                <Row>

                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="fluxoMedio">
                                                Fluxo médio:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.fluxoMedio ? true : false}
                                                type="number"
                                                name="fluxoMedio"
                                                id="fluxoMedio"
                                                register={register}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>



                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="quantidadeTelas">
                                                Quant. de telas:
                                            </Label>

                                            <FormInput
                                                invalid={false}
                                                type="number"
                                                name="quantidadeTelas"
                                                id="quantidadeTelas"
                                                register={register}

                                            />


                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="urlVidTerminal">
                                                Link vídeo / instagram terminal:
                                            </Label>

                                            <FormInput
                                                invalid={false}
                                                type="text"
                                                name="urlVidTerminal"
                                                id="urlVidTerminal"
                                                register={register}
                                                defaultValue="Nenhum vídeo"

                                            />


                                        </FormGroup>
                                    </Col>


                                </Row>

                                <Row>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="descricaoTerminal">
                                                Descrição:
                                            </Label>

                                            <FormInput
                                                invalid={false}
                                                type="textarea"
                                                rows="5"
                                                name="descricaoTerminal"
                                                id="descricaoTerminal"
                                                register={register}
                                                defaultValue="Localizado em uma área estratégica de grande circulação,
                                                 este espaço oferece alta visibilidade para sua marca.  "

                                            />



                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="horarioFun">
                                                Horário funcionamento:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.horarioFun ? true : false}
                                                type="textarea"
                                                name="horarioFun"
                                                id="horarioFun"
                                                register={register}
                                                rows="5"
                                                defaultValue={`SEG. A SEX.: 00:00 ÀS 00:00
SAB. 00:00 ÀS 00:00
DOM. 00:00 ÀS 00:00`}
                                            />

                                            <FormFeedback >
                                                Campo obrigatório!
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="obsTerminal">
                                                Observações:
                                            </Label>

                                            <FormInput
                                                invalid={false}
                                                type="textarea"
                                                name="obsTerminal"
                                                id="obsTerminal"
                                                register={register}
                                                rows="5"
                                                defaultValue="Estamos à disposição para personalizar a campanha de acordo com
                                                 suas necessidades e objetivos. Vamos potencializar sua comunicação? 🚀"

                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>



                                <FormGroup check>

                                    <FormInput
                                        invalid={errors?.empresaAtiva ? true : false}
                                        type="checkbox"
                                        name="empresaAtiva"
                                        id="empresaAtiva"
                                        register={register}


                                    />

                                    <Label
                                        check
                                        for="empresaAtiva"
                                    >
                                        Empresa ativa
                                    </Label>
                                </FormGroup>



                            </Form>
                        </div>



                        <div className="box-footer">
                            <Card style={{ border: 'none' }}>
                                <CardFooter>
                                    <Button disabled={getLoad} color="success" onClick={() => {

                                        handleSubmit((data) => salvarNovoAnuncio(data))()

                                    }}>
                                        Salvar
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                        {getLoad && <Progress animated value={100} />}
                    </div>
                </ModalBody>
                
                <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />

            </Modal>

        </>


    )
}


