
import { useEffect, useState } from 'react'
import './uploadImg.css'
import { SERVER } from '../../../util/conect'
import {
    Input, FormText, Button, Modal, ModalBody, ModalFooter, Progress, ModalHeader
} from 'reactstrap'
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'
export default function UploadImg({ idUpload, nameUpload, formTextUpload,  setProposta, Proposta, rotaUpload }) {


    //options SERVER
    const [_, setResponseContext] = useState();
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);

    const [uploadImg, setUploadImg] = useState(false)

    const [getImagem, setImagem] = useState()
    const [modal, setModal] = useState(false);



    useEffect(() => {

        if (responseApi.response) {
            setProposta((body) => {
                return {
                    ...body,
                    imagens: responseApi.response.imagens
                }
            })
        }

    }, [responseApi])

    const toggle = () => setModal(!modal);

    const getFile = (e) => {

        const files = e.target.files[0]
        const formData = new FormData()
        formData.append('image', files)

        setUploadImg(formData)

    }

    const uploadImagem = async () => {

       
        
            await SERVER({
                rota: 'upload-imagem',
                metodo: 'POST',
                body: uploadImg,
                id: rotaUpload,
                setLoad: setLoad,
                setResponseApi: setResponseApi,
                openAlert: onDismiss,
                setResponseContext: setResponseContext,
                formData: true

            })
        

    }

    const deleteImagem = async (bodyImg) => {

       

            const bodyEnvio = {
                deleteId: bodyImg.deleteId,
                id: Proposta._id,
                idImg: bodyImg._id,
            }
            await SERVER({
                rota: 'delete-imagem',
                metodo: 'DELETE',
                body: bodyEnvio,
                id: rotaUpload,
                setLoad: setLoad,
                setResponseApi: setResponseApi,
                openAlert: onDismiss,
                setResponseContext: setResponseContext,


            })


            toggle()
        

       

    }

   

    const atualizarImagem = (img) => {

        const { imagensQuemSomos } = Proposta;

        if(imagensQuemSomos.includes(img)){
            return 
        }

        if (imagensQuemSomos?.length < 2) {              
           
                  
            setProposta((body) => {
                return {
                    ...body,
                    imagensQuemSomos: [...body.imagensQuemSomos, img]
                }
            })

           
        } else {
            setProposta((body) => {
                return {
                    ...body,
                    imagensQuemSomos: [img, imagensQuemSomos[0]]
                }
            })
        }

    }

    const atualizarImagemLogo = (img) => {

        setProposta((body) => {
            return {
                ...body,
                logo: img
            }
        })


    }



    const PrintBody = () => {

      
            if (Array.isArray(Proposta?.imagens) && Proposta?.imagens?.length > 0) {
                return (
                    <>


                        {
                            Proposta?.imagens?.map(val => (
                                <div className="logoMarcas" key={val._id}>
                                    <img src={val.url} alt="Imagem logo" onClick={() => {
                                        toggle()
                                        setImagem({ img: val.url, ...val })
                                    }} />
                                </div>
                            )

                            )
                        }
                    </>
                )
            }
        

    }

    return (
        <>
            <Input
                id={idUpload}
                name={nameUpload}
                type="file"
                onChange={(e) => getFile(e)}
            />

            <FormText>
                {formTextUpload}
            </FormText>

            <p></p>

            <Button className='btnUpload' color='success' onClick={uploadImagem} disabled={getLoad ? true : false}>
                {getLoad ? 'Carregando' : 'Upload'}
            </Button>



            <div className="boxImagemMarcas">
                <PrintBody />
            </div>

            <Modal isOpen={modal} toggle={toggle} fullscreen="lg">
                 <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div className="logoMarcasModal">
                        <img src={getImagem?.img} alt="Imagem clientes" width={400} />
                    </div>
                </ModalBody>

                <ModalFooter style={{ gap: '5px' }}>



                    {rotaUpload === 'pdfProposta' && <Button color='primary' onClick={() => {
                        atualizarImagem(getImagem.img)
                        toggle()
                    }}>Usar no destaque</Button>}

                    {rotaUpload === 'pdfProposta' && <Button color='secondary' onClick={() => {
                        atualizarImagemLogo(getImagem.img)
                        toggle()
                    }}>Usar no Logotipo</Button>}

                    <Button color='danger' onClick={() => {

                        deleteImagem(getImagem)

                    }}>Excluir</Button>
                </ModalFooter>

                {getLoad && <Progress style={{margin: '10px'}} animated value={100} />}


            </Modal>
            <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />
        </>
    )
}