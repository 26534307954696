import {
    CardHeader, Card, CardBody, CardTitle, CardText, Button, CardFooter
} from 'reactstrap';
import { Link } from 'react-router-dom';

import TuneIcon from '@mui/icons-material/Tune';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import moment from 'moment';


export function SearchFilter(dadosFetch, buscarCliente, msg, campo) {




    let clientesEncontrado = []
    if (dadosFetch) {



        const regex = new RegExp(buscarCliente, 'img');
        let veriificarInput = (dadosFetch?.filter(objeto => regex.test(objeto[campo])))
        if (veriificarInput.length === 0) {
            clientesEncontrado = [{ novoPlano: true, msg, nomeDoPlano: buscarCliente }]
        } else {
            clientesEncontrado = buscarCliente.length > 0
                ? dadosFetch?.filter(objeto => regex.test(objeto[campo]))
                : null

        }



    }

    return clientesEncontrado
}


export function PrintCard(body, setDados, setModal, modal, rota, tipo, getDadosVendas, setavanca, status) {



    function reduzirTitulo(texto) {

        if (texto.length > 15) {
            return `${texto.slice(0, 15)}...`
        } else {
            return texto
        }

    }


    function QuantidadeClienteTerminal(nome1, nome2) {

        //nome1 nomes dos terminais
        //nome 2 nome dos terminal onde os anuncios estão rodando

        if (nome1 && nome2) {


            //pegar todos os clientes da venda ativo
            let arrayTerminal = []
            nome2.forEach((value) => {

                if (value.status && value.terminalOption.length > 0) {

                    if (value.status === 'ativo') {


                        arrayTerminal.push(value.terminalOption)
                    }

                }

            })

            //nomes do terminal sem o id
            const terminalOption = arrayTerminal.map((value, ind) => {
                return value.map((val, i) => {
                    return val.split('-')[1]
                })
            }
            )


            let clientesNoterminal = []
            terminalOption.map((val) => {

                if (val.includes(nome1)) {
                    clientesNoterminal.push(true)
                }

            })



            //quantidade de cleintes usando o terminal        
            return clientesNoterminal.length



        }
    }

    if (body?.novoPlano) {
        return (
            <>
                {tipo}:<strong>{` ${body.nomeDoPlano.toUpperCase()} `}</strong>  não encontrado
                <Link to={`/${rota}`}> Clique aqui para cadastrar.</Link>

            </>
        )
    }

    if (tipo === 'Cliente') {

        return (
            <Card id='list' className='card-img'>
                <CardHeader id='topo-card-list'>
                <span data-tooltip={body.nomeEmpresa}>{reduzirTitulo(body.nomeEmpresa)}</span>
                   
                    <PlaylistAddIcon
                        onClick={() => {
                            setDados(body)

                            setModal(() => !modal)


                        }}>
                    </PlaylistAddIcon>
                </CardHeader>

                <CardBody id="card-clientes">


                    <div>
                        <div style={{ fontSize: '.9rem' }}>Cidade: {reduzirTitulo(body.cidadeEmpresa)}</div>
                        <div style={{ fontSize: '.9rem' }}>Bairro: {reduzirTitulo(body.bairroEmpresa)}</div>
                        <div style={{ fontSize: '.9rem' }}>Ativo: {body.empresaAtiva ? 'sim' : 'não'}</div>
                    </div>

                </CardBody>

            </Card>
        )

    }

    if (tipo === 'cliente') {

        return (
            <Card id='list' className='card-img'>
                <CardHeader id='topo-card-list'>
                    
                    <span data-tooltip={body.nomeEmpresa}>{reduzirTitulo(body.nomeEmpresa)}</span>
                    <TuneIcon
                        onClick={() => {
                            setDados(body)
                            setModal(() => !modal)


                        }}>
                    </TuneIcon>
                </CardHeader>

                <CardBody id="card-clientes">


                    <div>
                        <div style={{ fontSize: '.9rem' }}>Cidade: {reduzirTitulo(body.cidadeEmpresa)}</div>
                        <div style={{ fontSize: '.9rem' }}>Bairro: {reduzirTitulo(body.bairroEmpresa)}</div>
                        <div style={{ fontSize: '.9rem' }}>Ativo: {body.empresaAtiva ? 'sim' : 'não'}</div>
                    </div>

                </CardBody>

            </Card>
        )

    }

    if (tipo === 'terminal') {

        return (

            <>
                <Card id='list' className='quantiAnunciantesList'>
                    <CardHeader id='topo-card-list'>

                        <span data-tooltip={body.nomeEmpresa}>{reduzirTitulo(body.nomeEmpresa)}</span>

                        <TuneIcon
                            onClick={() => {


                                setDados(body)
                                setavanca((value) => !value)

                            }}>
                        </TuneIcon>
                    </CardHeader>
                    <CardBody>
                        <CardBody>

                            <div>

                                Anunciantes: {QuantidadeClienteTerminal(body._id, getDadosVendas)}
                            </div>


                        </CardBody>



                        <CardFooter id='card-terminal-ver'>



                            <Button className='btnVerTerminal' size='sm' color="primary" disabled={QuantidadeClienteTerminal(body._id, getDadosVendas) == 0 ? true : false} onClick={() => {
                                setDados(body)

                                setModal((data) => {
                                    return {
                                        body: getDadosVendas,
                                        abrir: !data.abrir
                                    }
                                })
                            }}>
                                Ver anunciantes
                            </Button>


                        </CardFooter>



                    </CardBody>

                </Card>


            </>
        )
    }

    if (tipo === 'plano') {
        return (
            <Card id='list'>
                <CardHeader id='topo-card-list'>                   
                    <span data-tooltip={body.nomePlano}>{reduzirTitulo(body.nomePlano)}</span>
                    <TuneIcon
                        onClick={() => {
                            setDados(body)
                            setModal(() => !modal)


                        }}>
                    </TuneIcon>
                </CardHeader>
                <CardBody>
                    <CardTitle tag="h5">
                        {Number(body.valorPlano).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        <div id="text-forma-pagamento-card">/{body.formPagemnto}</div>

                    </CardTitle>
                    <div >
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <span>{body.infoPlano?.length > 0 ? body.infoPlano.slice(0, 19) : ''}...</span>
                            <span style={{color: body.planoAtivo ? '#0c6301' : '#c20000'}}> {body.planoAtivo ? 'Proposta: Ativo' : 'Proposta: Desativado'}</span>
                        </div>
                    </div>

                </CardBody>

            </Card>
        )
    }

    //finaceiro
    if (tipo === 'anuncios') {



        return (

            <Card id='list-anuncio'>
                <CardHeader id='topo-card-list'>
                    <div className='info-card-1'>

                        <span>

                            <span>

                                <FiberManualRecordIcon style={{ marginBottom: '5px' }} color=
                                    {
                                        body.status === 'finalizado' ? 'error' : null ||
                                            body.status === 'ativo' ? 'success' : null ||
                                                body.status === 'pausado' ? 'warning' : null
                                    }


                                />
                            </span>

                            <span>{`${body.nomeEmpresa?.toUpperCase()}`}  {status ? ` - ${body.status.toUpperCase()}` : null}</span>


                        </span>
                        <span><TuneIcon
                            onClick={() => {

                                setDados(body)
                                setModal(() => !modal)
                            }}>
                        </TuneIcon></span>
                    </div>

                    <div className="info-card-2">

                        <span>{`${moment(body?.inicioVinculacao).format("DD/MM/YYYY")} `} até {body?.fim_Vinculacao}</span>
                    </div>

                </CardHeader>

            </Card>

        )



    }

}