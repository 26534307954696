



import { Navigate } from 'react-router';
import Cookies from 'js-cookie';




export default function Private({ children }) {

 
  let token = Cookies.get('stk-log') ? JSON.parse(Cookies.get('stk-log')).token : false;

 

  return token ? children : <Navigate to="/login" />


}