export function gerarQuantidade(tipo, quantContrato) {

    // {PrecoBrl(Number(dados.valor) * Number(dados.contratacao))}
    let retornoQuantidade = 0
    const forma = tipo.split('-')[0].replace(/[^\p{L}]/gu, '').toUpperCase();

    switch (forma) {
        case 'DIÁRIO':

            return retornoQuantidade = quantContrato

            break;
        case 'MENSAL':

            return retornoQuantidade = quantContrato
            break;

        case 'TRIMESTRAL':

            return retornoQuantidade = quantContrato * 3
            break;

        case 'ANUAL':

            return retornoQuantidade = quantContrato
            break;

        default:
            break;

    }

    return retornoQuantidade
}

export function NomeFormaPagamento(tipo, quant) {

   

    const forma = tipo.split('-')[0].replace(/[^\p{L}]/gu, '').toUpperCase();

    switch (forma) {
        case 'DIÁRIO':

            return quant > 1 ? 'dias' : 'dia'

            break;
        case 'MENSAL':

            return quant > 1 ? 'meses' : 'mês'
            break;

        case 'TRIMESTRAL':

            return 'meses'
            break;

        case 'ANUAL':

            return quant > 1 ? 'anos' : 'ano'
            break;

        default:
            break;
    }

}
