import React, { useState, useEffect } from 'react';

export default function TextoDigitando({ primeiroTexto = " ", segundoTexto = " ", speed = 100, }) {

    const [displayedText, setDisplayedText] = useState("");
    const [displayedText2, setDisplayedText2] = useState("");
    const [text2, setText2] = useState(false)

  
    if(primeiroTexto?.length === 0){

        primeiroTexto = ' '

    }

    useEffect(() => {

     

        if (displayedText.length < primeiroTexto?.length || primeiroTexto?.length === 0) {           

            const timeoutId = setTimeout(() => {

                setDisplayedText(primeiroTexto.substring(0, displayedText.length + 1));
                setText2(displayedText.length > primeiroTexto.length - 5)

            }, speed);

            return () => clearTimeout(timeoutId);
        }
       
        if (text2) {

           

            if (displayedText2.length < segundoTexto.length) {

                const timeoutId2 = setTimeout(() => {

                    setDisplayedText2(segundoTexto.substring(0, displayedText2.length + 1));

                }, speed);


            }
        }

       


    }, [displayedText, displayedText2, primeiroTexto, segundoTexto, speed]);


   

    return (
        <>
        
            <h1>{displayedText}</h1>
            <h2>{displayedText2}</h2>
        </>
    );
}


