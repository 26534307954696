
function formatPhoneNumber(number) {

    if (!number) {
        return ''
    }
    // Remove todos os caracteres não numéricos
    const cleaned = number.replace(/\D/g, "");

    // Adiciona a máscara ao número
    const formatted = cleaned.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, "($1) $2 $3-$4");

    return formatted;
}

export default function Rodape({ dadosEmpresa }) {

    return (
        <div className="footerProposta" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}>

            <div style={{ fontSize: '1.2rem' }}>
                {dadosEmpresa?.nomeEmpresaProposta}
            </div>

            <div className="contatoProposta">
                <div className="dadosEmpresa">

                    <div className="telProposta" >
                        <a style={{ color: '#fff', textDecoration: 'none' }} target="_blank" href={dadosEmpresa && "https://wa.me/55" + dadosEmpresa?.whatsApp}>
                            {formatPhoneNumber(dadosEmpresa?.whatsApp)}
                        </a>
                    </div>

                    
                </div>
            </div>
            <div className="docPorposta" >
                © Todos os direitos reservados - {dadosEmpresa?.cnpjEmpresaProposta}
            </div>
        </div>
    )
}