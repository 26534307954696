import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { useState } from "react";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Corrigir problema do ícone quebrado
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const customIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [15, 25], // Tamanho do ícone padrão do Leaflet
  iconAnchor: [12, 41], // Ponto de ancoragem do ícone
  popupAnchor: [1, -34], // Ponto de ancoragem do popup
  shadowSize: [25, 25], // Tamanho da sombra
});

export default function MapComponent({ empresa, setContrato, height }) {

  const [position, setPosition] = useState(null); // Estado para armazenar a posição do marcador



  function LocationMarker() {
    useMapEvents({
      click(e) {
        setPosition(e.latlng); // Captura latitude e longitude ao clicar no mapa
        setContrato((body) => {
          return {
            ...body,
            lat: e.latlng.lat,
            lon: e.latlng.lng,
          }
        })
      },
    });

    return position ? <Marker position={position} icon={customIcon} /> : <Marker position={{ lat: empresa.lat, lng: empresa.lon }} icon={customIcon} />;
  }

  return (
    <div>

      <MapContainer
        center={[empresa?.lat || -15.7801, empresa?.lon || -47.9292]} // Centro inicial (Brasília)
        zoom={13}
        style={{ height: height, width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LocationMarker />
      </MapContainer>

    </div>
  );
}
