
import './estatisticas.css'

import IndexContador from '../contador/indexContador'
import ImageSlideshow from './imageSlideShow'

export default function Estatisticas({ dadosEmpresa }) {


    function fVerificarMinheight() {

        if (dadosEmpresa?.insercoesMes) {
            return {
                minHeight: "450px",
                maxHeight: "450px",
                backgroundColor: dadosEmpresa?.corSelecionada

            }
        } else {
            //verifcar tamnho da tela window


            if (window.innerWidth <= 768) {
                return {
                    minHeight: "0px",

                }

            } else {
                return {
                    minHeight: "450px",
                    maxHeight: "450px",
                }
            }
        }

    }

    return (
        <div id="ContainerEstatisticas" style={fVerificarMinheight()}>

            <div className={`boxEstatisticasUm ${dadosEmpresa?.marcasClientes?.length > 0 && 'boxEstatisticasUmMobile'}`} style={{ backgroundColor: dadosEmpresa?.corSelecionada }}>
                <span className='textEstatisticas textEstUm fw-100' >Mais de</span>
                <span className='textEstatisticas textEstQuant'>

                    <IndexContador alvo={dadosEmpresa?.quantInsercoes ? dadosEmpresa?.quantInsercoes : 0} count={.5} />
                </span>
                <span className='textEstatisticas textEstDois fw-100'>exibições por mês</span>
            </div>




            {dadosEmpresa?.imagens?.length > 0 && <ImageSlideshow images={dadosEmpresa.imagens} ajusteImgExtra={dadosEmpresa?.ajusteImgExtra} />}

            {/* {
            dadosEmpresa?.insercoesMes && <div className="boxEstatisticasDois" style={
                {
                    backgroundImage: `url(${dadosEmpresa?.insercoesMes})`,
                    opacity: ".7",
                    backgroundSize: dadosEmpresa.ajusteImgExtra
                }
            } >
            </div>
            } */}

        </div>

    )
}


