import './css/login.css'


import { Navigate } from 'react-router-dom'
import { useState } from 'react'
import { Progress } from 'reactstrap';

import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';


//função HeadersGet
import { HeadersPost } from '../../util/headers'



import Cookies from 'js-cookie';
import { UrlServer } from '../../util/conect';

export default function Login() {

    const { user, setUser } = useContext(UserContext)
  

    const [bodyInput, setBody] = useState()
    
    const [progresso, setProgresso] = useState(false)
    const [msgLogin, setmsgLogin] = useState()



    async function ConectURL() {

        let enviarDados = bodyInput

        if (!bodyInput?.email) {

            if (localStorage.getItem('stk-em')) {

                enviarDados = {...bodyInput, email: localStorage.getItem('stk-em')}

            }

        }



       
        setProgresso(true)
        await fetch(`${UrlServer()}/login-user`, {
            method: 'POST',
            headers: HeadersPost(),
            body: JSON.stringify(enviarDados)
        })
            .then(response => response.json())
            .then((body) => {


                localStorage.setItem('stk-em', enviarDados.email)

                setProgresso(false)

                if (body.connected) {

                    setmsgLogin(body.msg)

                    if (body.msg === "Logado") {


                        setUser(true)

                        const dados = JSON.stringify(
                            {
                                id: body.idUSer,
                                token: body.token,
                                nome: body.nome,
                                dbNome: body.dbNome,
                               // findToken: body.findToken,
                                access: body.access
                            }
                        )

                        Cookies.set('stk-log', dados, { expires: 1 })
                       

                        

                    }

                }

            })
            .catch(err => {
                setProgresso(false)
                console.log(err)
            })
    }



    return (
        <div id="container-login">
            {user && <Navigate to="/" />}
            
            <div className="container-login">
                <div className="content first-content">
                    <div className="first-column">

                        <h2 className="title title-primary">bem-vindo</h2>

                    </div>
                    <div className="second-column">

                        <form className="form">

                            <label className="label-input" >
                                <i className="far fa-envelope icon-modify"></i>
                                <input type="email" placeholder="Nome / Email / Telefone" autoComplete='email'
                                    onChange={(e) => {

                                        setBody((data) => {
                                            return { ...data, email: e.target.value }
                                        })

                                    }}
                                    defaultValue={localStorage.getItem('stk-em') ? localStorage.getItem('stk-em') : ''}
                                >
                                </input>
                            </label>

                            <label className="label-input" >
                                <i className="fas fa-lock icon-modify"></i>
                                <input type="password" placeholder="Senha"
                                    onChange={(e) => {

                                        setBody((data) => {
                                            return { ...data, senha: e.target.value }
                                        })

                                    }}>

                                </input>
                            </label>

                            <div className="msg-recebida">
                                {msgLogin && <span>{msgLogin}</span>}
                            </div>



                            <button className="btn-login btn-second" onClick={(e) => {
                                e.preventDefault()

                                //metodo,rota, msg, body, setfetchErro, id
                                ConectURL()




                            }}>Entrar</button>
                        </form>


                        <div className='progress-login' style={{ visibility: progresso ? '' : 'hidden' }}>
                            <Progress
                                animated
                                className="my-3"
                                color="warning"
                                striped
                                value={100}
                            />
                        </div>



                    </div>
                </div>
                <div className="content second-content">


                </div>
            </div>
        </div>
    )

}