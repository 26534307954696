import './settings.css'

import { useEffect, useState } from 'react';
import { ConectURL, UrlServer } from '../../util/conect';
import { SERVER } from '../../util/conect'
//props alerta info cadastro
import SuccessEnvioDados from '../../util/SuccessEnvioDados'
import Cookies from 'js-cookie';
import {
    Input, Row, Col, Form, FormGroup, Label, Button, Alert, Progress, Modal, ModalBody, ModalFooter, ModalHeader, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap'

import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ArticleIcon from '@mui/icons-material/Article';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupIcon from '@mui/icons-material/Group';
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import BoxConfig from './boxConfig';
import { HeadersGet } from '../../util/headers';
import CorPrincipal from './CorPrincipal';
import ModeloSite from './modeloSite/modeloSite';
import BackgroundVideo from './backgroundVideo/backgroundVideo';
import UploadImg from './uploadImg/uploadImg';

import IconesCard from '../proposta/IconesCard/IconesCard';
import { Link } from 'react-router-dom';

import GeneratePDF from '../../page/proposta/gerar-proposta/page'

export default function Settings() {



    //options SERVER
    const [_, setResponseContext] = useState();
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);

    const [newSenha, setSenha] = useState({})
    const [newTextZap, setTextZap] = useState({})
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })
    const [alertTg, setAlertTg] = useState(true);
    const [modal, setModal] = useState(false);
    const [tipo, setTipo] = useState(false);
    const [Proposta, setProposta] = useState({})
    const [relatorio, setRelatorio] = useState({})
    const [propostaPersonalizada, setPropostaPersonalizada] = useState()
    const [pdfPropostaPersonalizada, setPdfPropostaPersonalizada] = useState()
    const [openPropostaPersonalizada, setOpenPropostaPersonalizada] = useState(false);
    const [optionsTerminal, setTerminais] = useState([]);
    const [getPdfproposta, setPdfproposta] = useState();
    const [getDadosPdfproposta, setDadosPdfproposta] = useState();
    const [getTrocarPlanos, setTrocarPlanos] = useState(true);
    //accordion
    const [openAccordion, setOpenAccordion] = useState('0');


    const [getEditarProposta, setEditarProposta] = useState(false);

    useEffect(() => {
        tipo === 'PDF Proposta' ? setOpenAccordion('1') : setOpenAccordion('0')
    }, [tipo])

    const animatedComponents = makeAnimated();

    const access = JSON.parse(Cookies.get('stk-log'))?.access

    const toggleAccordion = (id) => {
        if (openAccordion === id) {
            setOpenAccordion();
        } else {
            setOpenAccordion(id);
        }
    };


    const toggle = () => setModal(!modal);

    const togglePropostaPersonalizada = () => setOpenPropostaPersonalizada(!openPropostaPersonalizada)


    useEffect(() => {

        fetch(`${UrlServer()}/buscar-texto`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.response) {

                    setTextZap(
                        {
                            textoZapBody: data.response[0].textoZapBody,
                            textoZapFooter: data.response[0].textoZapFooter,
                            id: data.response[0]._id,


                        }
                    )
                } else {
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: data.msg
                        }
                    })
                }
            }
            ).catch((err) => {
                console.log('Error: ', err)
            })

        //buscar dadsos server conf proposta
        fetch(`${UrlServer()}/buscar-proposta/`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                if (data.response) {

                    const res = {
                        cnpjEmpresaProposta: data.response[0].cnpjEmpresaProposta,
                        corSelecionada: data.response[0].corSelecionada,
                        emailProposta: data.response[0].emailProposta,
                        instagram: data.response[0].instagram,
                        nomeEmpresaProposta: data.response[0].nomeEmpresaProposta,
                        tituloPrimario: data.response[0]?.tituloPrimario,
                        tituloSecundario: data.response[0].tituloSecundario,
                        whatsApp: data.response[0].whatsApp,
                        _id: data.response[0]._id,
                        quantTelas: data.response[0].quantTelas,
                        quantClientes: data.response[0].quantClientes,
                        quantCidade: data.response[0].quantCidade,
                        quantInsercoes: data.response[0].quantInsercoes,

                        videoBg: data.response[0]?.videoBg || 1,
                        habilitarBtn: data.response[0]?.habilitarBtn || false,
                        corBtn: data.response[0].corBtn || data.response[0].corSelecionada,

                        marcasClientes: data.response[0].marcasClientes,
                        marcasClientesDelete: data.response[0].marcasClientesDelete,
                        insercoesMes: data.response[0].insercoesMes,
                        insercoesMesDelete: data.response[0].insercoesMesDelete,

                        iconeCor: data.response[0].iconeCor || "#515151",
                        iconeCard: data.response[0].iconeCard || "Icon_01",
                        iconeTamanho: data.response[0].iconeTamanho || "4",

                        ajusteImgExtra: data.response[0].ajusteImgExtra || "cover",

                        quemSomos1: data.response[0].quemSomos1,
                        quemSomos2: data.response[0].quemSomos2,

                        todosTerminais: data.todosTerminais,
                        imagens: data.response[0].imagens,

                    }

                    setProposta(res)

                    setPropostaPersonalizada((body) => {
                        return {
                            ...body,
                            locais: data?.todosTerminais && data.todosTerminais.map(item => item.nomeEmpresa).join(', '),
                            quantidadeLocais: data.todosTerminais?.length,
                            quantidadeLocaisTerminais: data.todosTerminais?.length,
                            dataPDF: new Date().toLocaleDateString('pt-BR'),
                            fluxoMedio: data?.todosTerminais && data.todosTerminais.reduce((acc, item) => acc + Number(item.fluxoMedio), 0)
                        }
                    })

                    let terminais =
                        data?.todosTerminais?.map((nome) => {
                            return (
                                {
                                    value: `${nome.nomeEmpresa}-${nome._id}`,
                                    label: nome.nomeEmpresa
                                })

                        })

                    setTerminais(terminais)

                }
            }
            ).catch((err) => {
                console.log('Error: ', err)
            })

        //buscar relatio
        fetch(`${UrlServer()}/relatorios`, HeadersGet())
            .then(response => response.json())
            .then(data => {

                if (data.response) {

                    setRelatorio(data.response)

                }
            }
            ).catch((e) => console.log(e));


        //buscar DADOS PDF PROPOSTA
        fetch(`${UrlServer()}/pdf-proposta`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                if (data.response) {

                    setDadosPdfproposta(data)
                    setPdfproposta(data.response.buscarPDFProposta)


                }
            }
            ).catch((e) => console.log(e));




    }, [])





    async function SalvarDados(parm) {

        switch (parm) {
            case 'Conta':
                ConectURL(
                    'PATCH',
                    'editar-sn',
                    'Senha Atualizada',
                    newSenha,
                    setfetchErro,
                    (JSON.parse(Cookies.get('stk-log'))).id,

                )
                break;
            case 'Whatsapp':

                ConectURL(
                    'PATCH',
                    'editar-texto',
                    'Texto Atualizada',
                    newTextZap,
                    setfetchErro,
                    newTextZap.id,

                )
                break;
            case 'Proposta comercial':

                ConectURL(
                    'PATCH',
                    'editar-proposta',
                    'Dados Atualizada',
                    Proposta,
                    setfetchErro,
                    Proposta._id,

                )
                break;
            case 'Proposta personalizada':

                const { locais } = propostaPersonalizada;
                propostaPersonalizada.locais = Array.isArray(locais) ? locais.map(empresa => empresa.label).join(', ') : locais ? locais : false
                propostaPersonalizada.quantidadeLocais = Array.isArray(locais) ? locais.length : locais.split(', ').length
                propostaPersonalizada.corSelecionada = Proposta?.corSelecionada



                ConectURL(
                    'POST',
                    'proposta-personalizada',
                    'Proposta gerada',
                    propostaPersonalizada,
                    setPdfPropostaPersonalizada
                )

                break
            case 'PDF-Proposta':
                await SERVER({
                    rota: 'pdf-proposta',
                    metodo: 'PATCH',
                    body: getPdfproposta,
                    id: getDadosPdfproposta.response.buscarPDFProposta._id,
                    setLoad: setLoad,
                    setResponseApi: setResponseApi,
                    openAlert: onDismiss,
                    setResponseContext

                })
                break
            case 'Mercado Pago':

                await SERVER({
                    rota: 'token-mercado-pago',
                    metodo: 'PATCH',
                    body: { tokenMercadoPago: propostaPersonalizada?.tokenMercadoPago },
                    id: JSON.parse(Cookies.get('stk-log')).id,
                    setLoad: setLoad,
                    setResponseApi: setResponseApi,
                    openAlert: onDismiss,
                    setResponseContext
                })

                break;
            default:
                break;
        }



    }

    function alertToggle() {
        setAlertTg((to) => false)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }
    function verificarFetch() {




        if (fetchErro.res) {



            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'sucesso') {

                return (

                    <div id={alertTg ? 'alert-fetch-sucess' : null} className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {

                                if (fetchErro.msg !== "Texto Atualizada") {
                                    //Cookies.remove('stk-log')
                                    //window.location.reload();
                                } else {
                                    //window.location.reload();
                                }

                                //{ btn: false, res: true, tipo: 'carregando', msg: '' }
                                setfetchErro(body => {
                                    return {
                                        ...body,
                                        tipo: 'carregando'
                                    }
                                })
                                setAlertTg(al => !al)

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>

                    </div>

                )
            }
        }
    }

    function printEditarUser() {
        return (
            <>
                <Form>

                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for="textoZap">
                                    Nova senha:
                                </Label>
                                <Input autoComplete='' id='novaSenha' type='password' onChange={(e) => setSenha((val) => {
                                    return {
                                        ...val,
                                        senha: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <Label for="confirmeSenha">
                                    Confirmar senha:
                                </Label>
                                <Input autoComplete='' id='confirmeSenha' type='password' onChange={(e) => setSenha((val) => {
                                    return {
                                        ...val,
                                        confirmSenha: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>

                    </Row>
                </Form>
            </>
        )
    }

    function printwhatsapp() {



        return (
            <>
                <Form>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='textoZapBody'>Cabeçalho:</Label>
                                <Input rows="5" autoComplete='' id='textoZapBody' type='textarea' defaultValue={newTextZap.textoZapBody} onChange={(e) => setTextZap((val) => {
                                    return {
                                        ...val,
                                        textoZapBody: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='textoZapFooter'>Rodapé:</Label>
                                <Input rows="5" autoComplete='' id='textoZapFooter' type='textarea' defaultValue={newTextZap.textoZapFooter} onChange={(e) => setTextZap((val) => {
                                    return {
                                        ...val,
                                        textoZapFooter: e.target.value
                                    }
                                })}></Input>
                            </FormGroup>
                        </Col>
                    </Row>

                </Form>
            </>
        )
    }

    function PropostaComercial() {


        return (
            <div id='formPropostaComercial'>

                <div className="boxPropostaUm">
                    <ModeloSite dadosEmpresa={Proposta} setProposta={setProposta} />
                </div>

                <div className="boxPropostaDois">
                    <Accordion open={openAccordion} toggle={toggleAccordion}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">Dados da empresa</AccordionHeader>
                            <AccordionBody accordionId="1">


                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor='nomeEmpresaProposta' >Nome da Empresa:</Label>


                                        <Input id='nomeEmpresaProposta' defaultValue={Proposta?.nomeEmpresaProposta} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        nomeEmpresaProposta: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                    <Col md={12}>
                                        <Label htmlFor='cnpjEmpresaProposta'>CNPJ da Empresa:</Label>

                                        <Input id='cnpjEmpresaProposta' defaultValue={Proposta?.cnpjEmpresaProposta} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        cnpjEmpresaProposta: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor='zapPorposta'>WhatsApp:</Label>
                                        <Input type='number' id='zapPorposta' defaultValue={Proposta?.whatsApp} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        whatsApp: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                    <Col md={12}>
                                        <Label htmlFor='instamProposta' >Instagram:</Label>
                                        <Input id='instamProposta' defaultValue={Proposta?.instagram} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        instagram: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                    <Col>
                                        <Label htmlFor='emailPorposta'>Email:</Label>
                                        <Input id='emailPorposta' defaultValue={Proposta?.emailProposta} onChange={(e) => {
                                            setProposta((data) => {
                                                return (
                                                    {
                                                        ...data,
                                                        emailProposta: e.target.value
                                                    }
                                                )
                                            })
                                        }}></Input>
                                    </Col>
                                </Row>


                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="2">Título</AccordionHeader>
                            <AccordionBody accordionId="2">

                                <Row>

                                    <Col>
                                        <FormGroup>
                                            <Label for='tituloPrimario'>1º linha</Label>
                                            <Input rows="4" autoComplete='' id='tituloPrimario' type='textarea' defaultValue={Proposta?.tituloPrimario || ''}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                tituloPrimario: e.target.value.length === 0 ? ' ' : e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for='tituloSecundario'>2º linha:</Label>
                                            <Input rows="4" autoComplete='' id='tituloSecundario' type='textarea' defaultValue={Proposta?.tituloSecundario}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                tituloSecundario: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="3">Estatísticas</AccordionHeader>
                            <AccordionBody accordionId="3">



                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantTelas'>Quantidade de telas:</Label>
                                            <Input id='quantTelas' type='number' defaultValue={Proposta?.quantTelas}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantTelas: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantClientes'>Quantidade de clientes:</Label>
                                            <Input id='quantClientes' type='number' defaultValue={Proposta?.quantClientes}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantClientes: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantCidade'>Quantidade de cidades:</Label>
                                            <Input id='quantCidade' type='number' defaultValue={Proposta?.quantCidade}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantCidade: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>


                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for='quantInsercoes'>Quantidade de exibições/mês:</Label>

                                            <Input id='quantInsercoes' type='number' defaultValue={Proposta?.quantInsercoes}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quantInsercoes: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="4">Personalização</AccordionHeader>
                            <AccordionBody accordionId="4">
                                <Row>
                                    <Col>

                                        {/* Cor Prncipla */}
                                        <>

                                            <div className="boxCorTitulo">
                                                <div className='boxPersonalizarProposta'>
                                                    {Proposta && <CorPrincipal atual={Proposta?.corSelecionada} setProposta={setProposta} tipo={'corSelecionada'} />}
                                                </div>
                                                <div className='tituloPageProposta centralizarTexto'>
                                                    Cor principal
                                                </div>
                                            </div>

                                        </>

                                        {/* Botões */}
                                        <>

                                            <div className="boxCorTitulo">

                                                <div className='boxPersonalizarProposta'>
                                                    {Proposta && <CorPrincipal atual={Proposta?.corSelecionada} setProposta={setProposta} tipo={'corBtn'} />}
                                                </div>

                                                <div className='tituloPageProposta centralizarTexto'>
                                                    Cor dos botões
                                                </div>

                                            </div>

                                        </>
                                        {/* Icones */}
                                        <>

                                            <div className="boxCorTitulo">
                                                <div className="boxPersonalizarProposta">
                                                    <CorPrincipal atual={Proposta?.iconeCor} setProposta={setProposta} tipo={'iconeCor'} />
                                                </div>
                                                <div className='tituloPageProposta centralizarTexto'>
                                                    Cor do icone
                                                </div>
                                            </div>

                                            <div className="rangeIcone">

                                                <div className='tituloPageProposta centralizarTexto '>
                                                    Tamanho do icone
                                                </div>

                                                <Input
                                                    id="iconeTamanho"
                                                    name="range"
                                                    type="range"
                                                    max={10}
                                                    onChange={(e) => {

                                                        setProposta((body) => {
                                                            return {
                                                                ...body,
                                                                iconeTamanho: e.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>


                                            <div className="centralizarTexto">
                                                <IconesCard Proposta={Proposta} setProposta={setProposta} />
                                            </div>
                                        </>




                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionHeader targetId="6">Background vídeo</AccordionHeader>
                            <AccordionBody accordionId="6">
                                <Row>
                                    <Col>

                                        <div id='labelVid' className='tituloPageProposta centralizarTexto'
                                        >
                                            <Input
                                                defaultChecked={Proposta?.habilitarBtn}
                                                id="videoBG"
                                                type='checkbox'
                                                onClick={() => {

                                                    setProposta((data) => {
                                                        return {
                                                            ...data,
                                                            habilitarBtn: !Proposta?.habilitarBtn,
                                                            vidEscolhido: require(`../../videos/background/bgVid_${Proposta?.videoBg}.mp4`)
                                                        }
                                                    })

                                                }} />
                                            <Label htmlFor='videoBG'>Habilitar vídeo background</Label>

                                        </div>


                                        {Proposta?.habilitarBtn && <BackgroundVideo Proposta={Proposta} setProposta={setProposta} />}

                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader targetId="7">Quem Somos</AccordionHeader>
                            <AccordionBody accordionId="7">
                                <Row>
                                    <Col>

                                        <div className='tituloPageProposta centralizarTexto'>
                                            {`Texto de apresentação da empresa`}
                                        </div>
                                        <br></br>




                                        <FormGroup>
                                            <Label for='quemSomos1'>1º linha</Label>
                                            <Input rows="6" autoComplete='' id='quemSomos1' type='textarea' defaultValue={Proposta?.quemSomos1 || ''}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quemSomos1: e.target.value.length === 0 ? ' ' : e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>


                                        <FormGroup>
                                            <Label for='quemSomos2'>2º linha</Label>
                                            <Input rows="6" autoComplete='' id='quemSomos2' type='textarea' defaultValue={Proposta?.quemSomos2 || ''}
                                                onChange={(e) => {
                                                    setProposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                quemSomos2: e.target.value.length === 0 ? ' ' : e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                        </FormGroup>







                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionHeader targetId="8">Upload de fotos </AccordionHeader>
                            <AccordionBody accordionId="8">
                                <Row>
                                    <Col>

                                        <div className='tituloPageProposta centralizarTexto'>

                                        </div>
                                        <br></br>
                                        {/* {Proposta &&
                                            <UploadImg
                                                idUpload={"insercoesMes"}
                                                nameUpload={"insercoesMes"}
                                                formTextUpload={"Tamanho recomendado para Upload  1280 x 900"}
                                                chaveAPI={"0bbe7dd51ce9efe913437476f88671cf"}
                                                setProposta={setProposta}
                                                Proposta={Proposta}
                                                rotaUpload={"insercoesMes"}
                                            />} */}

                                        {Proposta &&
                                            <UploadImg
                                                idUpload={"siteProposta"}
                                                nameUpload={"siteProposta"}
                                                formTextUpload={"Tamanho recomendado para Upload  720 x 900"}
                                                chaveAPI={"0bbe7dd51ce9efe913437476f88671cf"}
                                                setProposta={setProposta}
                                                Proposta={Proposta}
                                                rotaUpload={"siteProposta"}

                                            />}

                                        {/* <div className="ajusteImagem">

                                            <div className='tituloPageProposta centralizarTexto'>
                                                Ajuste da imagem
                                            </div>


                                            <FormGroup tag="fieldset">

                                                <FormGroup check>
                                                    <Input
                                                        name="ajusteImgExtra"
                                                        id='contain'
                                                        type="radio"
                                                        onChange={(e) => {
                                                            setProposta((body) => {
                                                                return {
                                                                    ...body,
                                                                    ajusteImgExtra: e.target.id
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check>
                                                        Centralizar contain ideal para png
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        name="ajusteImgExtra"
                                                        type="radio"
                                                        id='cover'
                                                        onChange={(e) => {
                                                            setProposta((body) => {
                                                                return {
                                                                    ...body,
                                                                    ajusteImgExtra: e.target.id
                                                                }
                                                            })

                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check>
                                                        Ajustar tamanho total cover
                                                    </Label>
                                                </FormGroup>
                                            </FormGroup>
                                        </div> */}



                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        {access && <Button className='' style={{ width: '100%', margin: '10px 0' }} color="success"
                            onClick={(e) => {

                                SalvarDados('Proposta comercial')
                            }}
                        >
                            Salvar
                        </Button>}

                    </Accordion>
                </div>


            </div>
        )
    }

    //pdf prospota 
    function ComponetePdfProposta() {


        return (
            <div id='formPropostaComercial'>

                <div className="boxPropostaUmPDF">
                    <GeneratePDF
                        conf={getPdfproposta}
                        getDadosPdfproposta={getDadosPdfproposta}
                        SalvarDados={SalvarDados}
                        getLoad={getLoad}
                        getTrocarPlanos={getTrocarPlanos}
                        setTrocarPlanos={setTrocarPlanos}
                        propostaPersonalizada={propostaPersonalizada}
                        optionsTerminal={optionsTerminal}


                    />
                </div>

                {getTrocarPlanos && (
                    <>
                        {!getEditarProposta && <div className="btnEditar btnStyle" onClick={() => setEditarProposta((e) => !e)}>Editar</div>}

                        <div className={`boxPropostaDoisPdf overFlowYscrol ${getEditarProposta ? '' : 'boxPropostaDoisHide'}`}>
                            <div className="btnEditarModal btnStyle" onClick={() => setEditarProposta((e) => !e)}>Fechar</div>
                            <Accordion open={openAccordion} toggle={toggleAccordion}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">Nome do PDF:</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <Row>
                                            <Col md={12}>
                                                <Input id='nomeEmpresa' defaultValue={getPdfproposta?.nomeEmpresa} onChange={(e) => {
                                                    setPdfproposta((data) => {
                                                        return (
                                                            {
                                                                ...data,
                                                                nomeEmpresa: e.target.value
                                                            }
                                                        )
                                                    })
                                                }}></Input>
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionHeader targetId="2">Textos</AccordionHeader>
                                    <AccordionBody accordionId="2">

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='textoTitulo'>Descrição empresa</Label>
                                                    <Input rows="4" autoComplete='' id='textoTitulo' type='textarea' defaultValue={getPdfproposta?.textoTitulo || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        textoTitulo: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='tituloOndeEstamos'>Título onde estamos</Label>
                                                    <Input autoComplete='' id='tituloOndeEstamos' type='text' defaultValue={getPdfproposta?.tituloOndeEstamos || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        tituloOndeEstamos: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='tituloPlanos'>Título planos</Label>
                                                    <Input autoComplete='' id='tituloPlanos' type='text' defaultValue={getPdfproposta?.tituloPlanos || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        tituloPlanos: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='obsPlano'>Obs. planos</Label>
                                                    <Input autoComplete='' id='obsPlano' type='text' defaultValue={getPdfproposta?.obsPlano || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        obsPlano: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='obsRodape'>Obs. rodapé</Label>
                                                    <Input rows="7" autoComplete='' id='obsRodape' type='textarea' defaultValue={getPdfproposta?.obsRodape || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        obsRodape: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionHeader targetId="3">Estatísticas</AccordionHeader>
                                    <AccordionBody accordionId="3">



                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for='quantTelas'>Pessoas/dia:</Label>
                                                    <Input id='fluxoMedio' type='number' defaultValue={getPdfproposta?.fluxoMedio}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        fluxoMedio: Number(e.target.value)
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for='exibicoesMes'>Quantidade de exibições/mês:</Label>

                                                    <Input id='exibicoesMes' type='number' defaultValue={getPdfproposta?.exibicoesMes}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        exibicoesMes: e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for='quantCidade'>Quantidade de telas:</Label>
                                                    <Input id='telas' type='number' defaultValue={getPdfproposta?.telas}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        telas: Number(e.target.value)
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>
                                            </Col>



                                        </Row>

                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionHeader targetId="4">Personalização cores</AccordionHeader>
                                    <AccordionBody accordionId="4">
                                        <Row>
                                            <Col>

                                                {/* Cor Principal */}
                                                <div className="boxCorTitulo">
                                                    <div className='boxPersonalizarProposta'>
                                                        {getPdfproposta && <CorPrincipal atual={getPdfproposta?.corSelecionada} setProposta={setPdfproposta} tipo={'corSelecionada'} />}
                                                    </div>
                                                    <div className='tituloPageProposta centralizarTexto'>
                                                        Cor principal
                                                    </div>
                                                </div>

                                                {/*  Cor dos preços */}
                                                <div className="boxCorTitulo">
                                                    <div className='boxPersonalizarProposta'>
                                                        {getPdfproposta && <CorPrincipal atual={getPdfproposta?.corTextoPreco} setProposta={setPdfproposta} tipo={'corTextoPreco'} />}
                                                    </div>
                                                    <div className='tituloPageProposta centralizarTexto'>
                                                        Cor dos preços
                                                    </div>
                                                </div>

                                                {/* Cor textos info planos */}
                                                <div className="boxCorTitulo">
                                                    <div className='boxPersonalizarProposta'>
                                                        {getPdfproposta && <CorPrincipal atual={getPdfproposta?.corTextoBoxPlano} setProposta={setPdfproposta} tipo={'corTextoBoxPlano'} />}
                                                    </div>
                                                    <div className='tituloPageProposta centralizarTexto'>
                                                        Cor informações planos
                                                    </div>
                                                </div>

                                                {/* Cor bg planos */}
                                                <div className="boxCorTitulo">
                                                    <div className='boxPersonalizarProposta'>
                                                        {getPdfproposta && <CorPrincipal atual={getPdfproposta?.corBgBoxPlano} setProposta={setPdfproposta} tipo={'corBgBoxPlano'} />}
                                                    </div>
                                                    <div className='tituloPageProposta centralizarTexto'>
                                                        Cor bg planos
                                                    </div>
                                                </div>


                                                {/* Cor check */}
                                                <div className="boxCorTitulo">
                                                    <div className='boxPersonalizarProposta' style={{ display: 'flex', gap: '5px' }} onClick={(e) => {

                                                        setPdfproposta((data) => {
                                                            return (
                                                                {
                                                                    ...data,
                                                                    corIconeCheck: `${e.target.id === '0' ? 'check-pto' : 'check-bco'}`
                                                                }
                                                            )
                                                        })
                                                    }}  >
                                                        <span id="0" style={{ height: '20px', width: '20px', borderRadius: '4px', padding: '4px', background: '#000', border: 'solid 2px #bebebe', cursor: "pointer" }}></span>
                                                        <span id="1" style={{ height: '20px', width: '20px', borderRadius: '4px', padding: '4px', background: '#fff', border: 'solid 2px #bebebe', cursor: "pointer" }}></span>
                                                    </div>
                                                    <div className='tituloPageProposta centralizarTexto'>
                                                        Cor icone check
                                                    </div>
                                                </div>



                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>



                                <AccordionItem>
                                    <AccordionHeader targetId="7">Quem Somos</AccordionHeader>
                                    <AccordionBody accordionId="7">
                                        <Row>
                                            <Col>


                                                <FormGroup>
                                                    <Label for='tituloTextoRodape'>Titulo quem somos:</Label>
                                                    <Input autoComplete='' id='tituloTextoRodape' type='text' defaultValue={getPdfproposta?.tituloTextoRodape || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        tituloTextoRodape: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>


                                                <FormGroup>
                                                    <Label for='textoRodape'>Descrição:</Label>
                                                    <Input rows="6" autoComplete='' id='textoRodape' type='textarea' defaultValue={getPdfproposta?.textoRodape || ''}
                                                        onChange={(e) => {
                                                            setPdfproposta((data) => {
                                                                return (
                                                                    {
                                                                        ...data,
                                                                        textoRodape: e.target.value.length === 0 ? ' ' : e.target.value
                                                                    }
                                                                )
                                                            })
                                                        }}></Input>
                                                </FormGroup>







                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionHeader targetId="8">Upload de fotos </AccordionHeader>
                                    <AccordionBody accordionId="8">
                                        <Row>
                                            <Col>

                                                <div className='tituloPageProposta centralizarTexto'>

                                                </div>
                                                <br></br>


                                                {getPdfproposta &&
                                                    <UploadImg
                                                        idUpload={"marcasClientes"}
                                                        nameUpload={"marcasClientes"}
                                                        formTextUpload={"Tamanho recomendado para Upload  720 x 900"}
                                                        chaveAPI={"0bbe7dd51ce9efe913437476f88671cf"}
                                                        setProposta={setPdfproposta}
                                                        Proposta={getPdfproposta}
                                                        rotaUpload={"pdfProposta"}

                                                    />}




                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>



                            </Accordion>
                        </div>
                    </>
                )

                }


                <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />
            </div>
        )
    }

    function Relatorios() {

        return (
            <>
                Relatórios
            </>
        )
    }

    function MercadoPago() {

        return (
            <>
                <Row>
                    <Col md={16}>
                        <Label htmlFor='tokenMercadoPago' >Token Mercado Pago:</Label>
                        <Input type='text' id='tokenMercadoPago' onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        tokenMercadoPago: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>


            </>
        )
    }

    function Usuarios() {

        return (
            <>
                Relatórios
            </>
        )
    }



    function printBody(parm) {


        switch (parm) {
            case 'Conta':
                return { body: printEditarUser(), titulo: 'Alterar senha' }
                break;
            case 'WhatsApp':
                return { body: printwhatsapp(), titulo: 'Texto de cobrança' }
                break;
            case 'Proposta comercial':
                return { body: PropostaComercial(), titulo: 'Proposta comercial' }
                break;
            case 'Relatórios':
                return { body: Relatorios(), titulo: 'Relatórios' }
                break;
            case 'Usuários':
                return { body: Usuarios(), titulo: 'Usuários' }
                break;
            case 'PDF Proposta':
                return { body: ComponetePdfProposta(), titulo: 'PDF Proposta' }
                break;
            case 'Mercado Pago':
                return { body: MercadoPago(), titulo: 'Mercado Pago' }
                break;
            default:
                break;
        }
    }

    function btnSalvar(parm) {

        if (parm === 'Relatórios') {
            return
        }


        if (parm === 'PDF Proposta') {
            return
        }

        function gerarLink() {

            function gerarDados() {

                if (!Proposta?.corSelecionada) {

                    fetch(`${UrlServer()}/buscar-proposta`, HeadersGet())
                }

            }

            const criarLink = () => {
                const getCook = JSON.parse(Cookies.get('stk-log')).dbNome.split('_')[1];
                const nameUser = getCook.includes('stokeplay') ? 'stoke' : getCook;

                return `https://${nameUser}.stokeplay.com.br`
            }


            if (tipo === 'Proposta comercial') {
                return (

                    <div className='LinkProposta'>
                        <a onClick={() => gerarDados()} target='_blank' rel="nooreferrer"
                            href={criarLink()}>
                            Acessar proposta
                        </a>
                    </div>
                )
            } else {
                return (
                    <div></div>
                )
            }
        }

        return (
            <>
                <div id="rodape-btn-salvar">

                    {getLoad && <Progress animated value={100} color="warning" />}

                    <div className={`box-footer ${access && 'not-acess'} `}>

                        {
                            fetchErro.msg === 'As senhas não conferem!' ? (<Alert color="danger" isOpen={true}  >
                                <code style={{ fontSize: '.8rem' }}>
                                    {fetchErro.msg}
                                </code>
                            </Alert>) : ''
                        }




                        <div className={`grup-btn-footer ${access ?? 'alowAccess'}`}>

                            {gerarLink()}


                            {access && parm === 'Proposta comercial' ? <Button color='primary'
                                onClick={() => togglePropostaPersonalizada()}
                            >
                                Proposta personalizada
                            </Button> : null}
                        </div>


                        {access && parm !== 'Proposta comercial' ? <Button color="success" className={`${'hide-btn-mobile'}`}
                            onClick={(e) => {
                                SalvarDados(parm)
                            }}
                        >
                            Salvar
                        </Button> : null}

                        {!access && <Button color="success" className={`${'hide-btn-mobile'}`}
                            onClick={(e) => {
                                SalvarDados(parm)
                            }}
                        >
                            Salvar
                        </Button>}


                    </div>


                </div>


            </>
        )

    }

    function ModalPropostaPersonalizada() {
        return (
            <>
                <Row>
                    <Col md={16}>
                        <Label htmlFor='investimento' >Investimento:</Label>
                        <Input type='number' id='investimento' onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        investimento: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>
                <Row>
                    <Col md={16}>
                        <Label htmlFor='periodo' >Período:</Label>
                        <Input id='periodo' onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        periodo: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>





                <Col style={{ marginTop: '10px' }} md={12}>
                    <FormGroup>
                        <Label for="locaisproposta">
                            Terminal:
                        </Label>
                        {optionsTerminal && <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={optionsTerminal}
                            placeholder='Digite o terminal'
                            noOptionsMessage={() => null}
                            defaultValue={optionsTerminal}
                            onChange={(e) => {

                                setPropostaPersonalizada((body) => {
                                    return {
                                        ...body,
                                        locais: e,
                                    }
                                })


                            }}
                        />
                        }
                    </FormGroup>
                </Col>



                <Row style={{ marginTop: '10px' }}>
                    <Col md={12}>
                        <Label htmlFor='obsProposta' >Observações:</Label>
                        <Input type="textarea" rows="3" id='obsProposta' maxLength={85} onChange={(e) => {
                            setPropostaPersonalizada((data) => {
                                return (
                                    {
                                        ...data,
                                        obsProposta: e.target.value
                                    }
                                )
                            })
                        }}></Input>
                    </Col>
                </Row>
            </>
        )
    }


    if (Proposta?._id) {
        return (
            <>
                <div id='page-conf' className='stk-body'>
                    <div className="titulo-um">
                        Configurações
                    </div>

                    <div className="box-conf">

                        <BoxConfig icone={<AccountCircleIcon />} titulo={'Conta'} texto={'Alterar senha de usuário'} toggle={toggle} setTipo={setTipo} />

                        <BoxConfig icone={<WhatsAppIcon />} titulo={'WhatsApp'} texto={'Alterar mensagem de cobrança'} toggle={toggle} setTipo={setTipo} />

                        <BoxConfig icone={<AddBusinessIcon />} titulo={'Proposta comercial'} texto={'Personalizar site proposta comercial'} toggle={toggle} setTipo={setTipo} />

                        {/* <BoxConfig icone={<AssessmentIcon />} titulo={'Relatórios'} texto={'Visualize relatório da sua empresa'} toggle={toggle} setTipo={setTipo} /> */}

                        <BoxConfig icone={<ArticleIcon color='error' />} titulo={'PDF Proposta'} texto={'Gere um arquivo PDF da sua proposta comercial'} toggle={toggle} setTipo={setTipo} />

                        {access && <Link to={'/listar-usuarios'} style={{ color: '#333333', textDecoration: 'none' }}>
                            <BoxConfig icone={<GroupIcon />} titulo={'Usuários'} texto={'Cadastrar e desativar usuários'} toggle={toggle} setTipo={setTipo} newPage={true} />
                        </Link>}

                         {<BoxConfig icone={<KeyRoundedIcon />} titulo={'Mercado Pago'} texto={'Configurar Token'} toggle={toggle} setTipo={setTipo} />}
                    </div>
                </div>


                <Modal isOpen={modal} toggle={toggle} centered fullscreen={tipo == 'Conta' ? false : true} >
                    <ModalHeader toggle={toggle}>{printBody(tipo)?.titulo}</ModalHeader>

                    <ModalBody>
                        {Proposta?._id && printBody(tipo)?.body}
                    </ModalBody>


                    {tipo !== 'PDF Proposta' && <ModalFooter>
                        {btnSalvar(tipo)}
                        {fetchErro.btn && verificarFetch()}
                    </ModalFooter>}

                    <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />

                </Modal>


                <Modal className='modal-proposta-personalizada' isOpen={openPropostaPersonalizada} toggle={togglePropostaPersonalizada} centered  >
                    <ModalHeader toggle={togglePropostaPersonalizada}>Proposta Personalizada</ModalHeader>
                    <ModalBody>

                        <ModalPropostaPersonalizada />



                    </ModalBody>
                    <ModalFooter>

                        {tipo === 'pdfProposta' ? (
                            <div className='footer-proposta-personalizada'>
                                <Button color='primary'
                                    onClick={() => {

                                        const { locais } = propostaPersonalizada;
                                        propostaPersonalizada.locais = Array.isArray(locais) ? locais.map(empresa => empresa.label).join(', ') : locais ? locais : false
                                        propostaPersonalizada.quantidadeLocais = Array.isArray(locais) ? locais.length : locais.split(', ').length
                                        propostaPersonalizada.corSelecionada = Proposta?.corSelecionada

                                        setPropostaPersonalizada((data) => {
                                            return (
                                                {
                                                    ...data,
                                                    ...propostaPersonalizada
                                                }
                                            )
                                        })

                                    }}
                                >
                                    Inserir dados
                                </Button>
                            </div>
                        ) : (
                            <div className='footer-proposta-personalizada'>
                                {pdfPropostaPersonalizada && (<div>
                                    <a className='link-pdf' target="_blank" download="proposta-personalizada.pdf" href={pdfPropostaPersonalizada}>
                                        Baixar PDF
                                    </a>
                                </div>)}
                                <Button color='primary'
                                    onClick={() => SalvarDados('Proposta personalizada')}
                                >
                                    Gerar PDF
                                </Button>
                            </div>
                        )}

                    </ModalFooter>


                </Modal>


            </>
        )
    } else {
        if (fetchErro?.msg === 'Sessão expirada') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com o servidor.
                            <br></br>

                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color="warning"
                        />
                    </div>
                </div>
            )
        }

    }

}