

import './propostas.css'


export default function Propostas({ plano, precoMes, pontos, infoPlano, optionsStyles, conf, contrato }) {




    const objStyleBoxPrice = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: conf.corTextoPreco,
        textAlign: 'center',
        width: '100%',
        borderRadius: '15px',
        backgroundColor: conf.corSelecionada,
        padding: '4px 0px',
        marginBottom: '-1px'

    }

    function RequireIconChek() {
        try {
            return <img src={require(`../../../../img/proposta/${conf?.corIconeCheck}.png`)} alt='Icone Check'></img>
        } catch (error) {
            return ''
        }
    }



    function PrintInfoPlano() {
        return (
            <>
                {pontos &&
                    <div className='card-filho'>
                        <RequireIconChek />
                        <div className="info" style={{ color: conf.corTextoBoxPlano }}>
                            {pontos} locais
                        </div>
                    </div>}
                {infoPlano.split('\n').map((conteudo, ind) => {
                    return (
                        <div key={ind} className='card-filho'>
                            <RequireIconChek />
                            <div className="info" style={{ color: conf.corTextoBoxPlano }}>
                                {conteudo}
                            </div>
                        </div>
                    )
                })}
            </>

        )





    }


    return (
        <div id={`card`} style={{ marginTop: optionsStyles }} >

            <div className="body-card">

                <div className="plano-card" style={{ fontWeight: '600' }}>{plano}</div>

                <div style={objStyleBoxPrice}>
                    <div style={{ fontSize: '1.1rem' }}>{precoMes} </div>
                    <div style={{ fontSize: '.6rem' }}>/{contrato}</div>
                </div>


                <div className="card-container" style={{ backgroundColor: conf.corBgBoxPlano }}>



                    <PrintInfoPlano />

                </div>

                {conf?.obsPlano && <div className='text-contratacao'>{conf?.obsPlano}</div>}
            </div>
        </div>
    )
}