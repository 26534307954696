import './marcasQueConfiam.css'
import FadeInOnScroll from '../../../components/framerMotion/framerMotion'

export default function MarcasQueConfiam({ dadosEmpresa }) {

    function hexToRgba(hex, alpha = 1) {
        let r = 0, g = 0, b = 0;


        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }

        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }


        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    function instagram(user) {
        if (user.includes('/')) {
            const arroba = user.split('/');

            if (arroba[arroba.length - 1] == "") {
                return arroba[arroba.length - 2]
            } else {
                return arroba[arroba.length - 1]
            }

        } else {
            return user;
        }

    }


    return (
        <>

            <div id="containerMarcasQueConfiam" style={{ backgroundColor: '#fff' || hexToRgba(dadosEmpresa?.corSelecionada, 0.07) }}>
                <div id="TituloPlano" className="nossosNumeros" style={{ color: dadosEmpresa?.corSelecionada }}>
                    {/* {`Marcas que confiam na ${dadosEmpresa.nomeEmpresaProposta}`} */}
                    Quem Somos
                </div>



                <div id='quem-somos'>


                    <div className='texto-quem-somos'>
                        <h1 className='fw-400'>
                            {dadosEmpresa?.quemSomos1}
                        </h1>



                        <h2 className='fw-300'>
                            {dadosEmpresa?.quemSomos2}
                        </h2>
                    </div>

                    <div className='center-btn-insta'>
                        <FadeInOnScroll  >
                            <div className='btn-instagram' style={{ backgroundColor: hexToRgba(dadosEmpresa?.corSelecionada), color: '#fff' }}>

                                <a target="_blank" rel="noopener noreferrer" href={dadosEmpresa && "https://www.instagram.com/" + instagram(dadosEmpresa?.instagram)}>
                                    Conheça mais sobre a {dadosEmpresa?.nomeEmpresaProposta}
                                </a>

                            </div>
                        </FadeInOnScroll>
                    </div>






                </div>

                {/* <div id="boxLogoEmpresas">
                    {dadosEmpresa?.marcasClientes.map((value) => {
                        return (
                            <div key={value} className="logoEmpresaQueConfiam">
                                <div className="imgBgLogo" style={{ backgroundImage: `url(${value})` }}>
                                    
                                </div>

                            </div>
                        )
                    })}
                </div> */}

            </div>

        </>
    )
}