import './cadastrarPlano.css'
import Select from 'react-select'
import React, { useContext, useState } from 'react';

import { UserContext } from '../../../context/UserContext';
import { Link } from 'react-router-dom'
import {
    Button, Col, Label, Form, Row, FormGroup,
    Card, CardFooter, ModalBody,
    Modal, ModalHeader, Progress, FormFeedback
} from 'reactstrap';

//props alerta info cadastro
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'

//importar função fetch
import { SERVER } from '../../../util/conect'



//validação de form
import { useForm } from 'react-hook-form';

//FormInpu paar validar dados
import FormInput from '../../../util/validarInput'


export default function CadastrarPlano() {

    //context
    const { setDadosAPI } = useContext(UserContext)
    //carregando dados recebido  
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);
    //enviar dados para o servidor

    const salvarNovoAnuncio = async (dadosParaEnvio) => {        
        await SERVER({
            rota: 'cadastro-plano',
            metodo: 'POST',
            body: {...dadosParaEnvio, formPagemnto: tipoContrato},
            setResponseContext: setDadosAPI,
            chaveContext: 'buscarClientePlanos',
            setLoad: setLoad,
            setResponseApi: setResponseApi,
            openAlert: onDismiss

        })
    }


    //validação de form 
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const [tipoContrato, settipoContrato] = useState('MENSAL');


    //aguardar tempo
    const [aguardeTempo, setaguardeTempo] = useState(false);

    //carregando dados recebido do fetch
    const [errorResponse, setErrrorResponse] = useState(false)
    const [loadResponse, setloadResponse] = useState(false)
    const [sucessoFetch, setsucessoFetch] = useState(false)

    return (

        <>

            <Modal isOpen={true} fullscreen>
                <ModalHeader id='modalTopo'>
                    <div>Cadastro de Plano</div>
                    <Link className='btn-close' to='/listar-planos'></Link>
                </ModalHeader>
                <ModalBody>

                    <div id='cadastrar-plano'>



                        <div className="box-shadow">
                            <Form>
                                <Row>


                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nomePlano">
                                                Nome do plano:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.nomePlano ? true : false}
                                                type="text"
                                                name="nomePlano"
                                                register={register}
                                                id="nomePlano"
                                                placeholder="Plano Premium"
                                            />

                                            <FormFeedback >
                                                Campo obrigatório
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>



                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="formPagemnto">
                                                Tipo de pagamento:
                                            </Label>

                                            <Select

                                                name="formPagemnto"
                                                id="formPagemnto"
                                                placeholder='Escolha'
                                                options={[
                                                    { value: 'DIÁRIO', label: 'Diário' },
                                                    { value: 'MENSAL', label: 'Mensal' },
                                                    { value: 'TRIMESTRAL', label: 'Trimestral' },
                                                    { value: 'ANUAL', label: 'Anual' },

                                                ]}

                                                onChange={(e) => {
                                                    settipoContrato(e.value)
                                                }}

                                            />

                                            <FormFeedback >
                                                Campo obrigatório
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>

                                            <Label for="valorPlano">
                                                Valor:
                                            </Label>

                                            <FormInput
                                                invalid={errors?.valorPlano ? true : false}
                                                type="number"
                                                name="valorPlano"
                                                id="valorPlano"
                                                register={register}
                                                placeholder="250"
                                            />

                                            <FormFeedback >
                                                Campo obrigatório
                                            </FormFeedback>

                                        </FormGroup>
                                    </Col>


                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="quantTerminal">
                                                    Quantidade de terminais:
                                                </Label>

                                                <FormInput
                                                    invalid={errors?.quantTerminal ? true : false}
                                                    type="number"
                                                    name="quantTerminal"
                                                    register={register}
                                                    id="quantTerminal"
                                                    placeholder=""
                                                />

                                                <FormFeedback >
                                                    Campo obrigatório
                                                </FormFeedback>

                                            </FormGroup>
                                        </Col>

                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="labelPlano">
                                                    Rótulo caixa de preço (opcional):
                                                </Label>

                                                <FormInput
                                                    type="text"
                                                    name="labelPlano"
                                                    register={register}
                                                    id="labelPlano"
                                                    placeholder="Ex: economize R$ 90,00"
                                                    defaultValue=' '
                                                />

                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>

                                                <Label for="infoPlano">
                                                    Descrição do plano:
                                                </Label>

                                                <FormInput
                                                    invalid={errors?.infoPlano ? true : false}
                                                    type="textarea"
                                                    name="infoPlano"
                                                    id="infoPlano"
                                                    register={register}
                                                    placeholder="02 pontos comerciais e 01 vídeio de 15s grátis."
                                                    rows="5"
                                                />

                                                <FormFeedback >
                                                    Campo obrigatório
                                                </FormFeedback>

                                            </FormGroup>
                                        </Col>
                                    </Row>



                                </Row>

                                <FormGroup check>

                                    <FormInput
                                        invalid={errors?.planoAtivo ? true : false}
                                        type="checkbox"
                                        name="planoAtivo"
                                        id="planoAtivo"
                                        register={register}

                                    />

                                    <FormFeedback >
                                        Campo obrigatório
                                    </FormFeedback>

                                    <Label
                                        check
                                        for="planoAtivo"
                                    >
                                        Plano visível na proposta comercial
                                    </Label>
                                </FormGroup>

                            </Form>
                        </div>





                        <div className="box-footer">
                            <Card style={{ border: 'none' }}>
                                <CardFooter>

                                    <Button disabled={getLoad} color="success" onClick={() => {

                                        handleSubmit((data) => salvarNovoAnuncio(data))()
                                    }}>
                                        Salvar
                                    </Button>

                                </CardFooter>
                            </Card>
                        </div>
                        {getLoad && <Progress animated value={100} />}
                    </div>


                </ModalBody>

                <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />

            </Modal>

        </>


    )
}


