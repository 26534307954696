import CardPropostas from './CardPropostas';




import { PrecoBrl } from '../../../../util/precoBrl';

export default function Planos({ conf, todosPlanos, margem }) {



    const PrintBodyPlanos = () => {

        return todosPlanos.filter(ativo => ativo.planoAtivo).map(plano => {

            return (
                <CardPropostas
                    key={plano._id}
                    optionsStyles={margem}                   
                    plano={plano.nomePlano}
                    precoMes={PrecoBrl(plano?.valorPlano)}
                    contrato={plano.formPagemnto}
                    precoTotal={'599,99'}
                    mesAno={'Total 3 meses'}
                    pontos={plano.quantTerminal}
                    infoPlano={plano.infoPlano}
                    conf={conf}
                />
            )
        })
    }

    function PrintInfoPlano() {

        return (
            <>
                {
                    conf.obsRodape.split('\n').map((text, ind) => (
                        <div key={ind} className="vantagens-texto">
                            <div className="elipse" style={{borderRadius: '100%', background: "#000", height: '5px', width: '5px'}}></div>
                            {text}
                        </div>
                    ))
                }
            </>
        )


    }

    return (
        <>
       
            <h1 className='tituloPlanos' style={{ color: conf.corSelecionada }}>{conf?.tituloPlanos || 'Planos'}</h1>
            <div id='proposta-card' className="margem-body ml mr">
                <PrintBodyPlanos />
            </div>
           
            {conf?.obsRodape && <div className="margem-body alinhamentoObs">
                <div id='vantagens'>
                    <PrintInfoPlano />
                </div>
            </div>}
        </>
    )
}