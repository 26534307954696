
import { Alert } from 'reactstrap';



export default function SuccessEnvioDados({ menssagem, visible, onDismiss, danger }) {

    

    return (
        <>
            {visible &&
                <div id='alert-fetch-sucess' className='sucessoFetch'>
                    <Alert color={danger ? 'danger' : "info"} isOpen={visible} toggle={onDismiss}
                        style={{ whiteSpace: "pre-line" }}>
                        {menssagem}
                    </Alert>
                </div>
            }
        </>
    )
}