import React, { useState, useContext, useEffect } from 'react';
import './btnMercadoPago.css'

import { Link, Navigate } from 'react-router-dom';
import {
    Button, Input, Col, Label, Row,
    Card, CardFooter, Progress, Alert,

} from 'reactstrap';

import { UserContext } from '../../../context/UserContext';

//importar função fetch
import { SERVER } from '../../../util/conect'

import { gerarQuantidade, NomeFormaPagamento } from '../../../util/manipularDadosContrato';
//props alerta info cadastro
import SuccessEnvioDados from '../../../util/SuccessEnvioDados'

import { PrecoBrl } from '../../../util/precoBrl'





export default function Cliente({ dados, setModal, setDados }) {


    //context
    const { setDadosAPI } = useContext(UserContext)
    //carregando dados recebido  
    const [getLoad, setLoad] = useState(false);
    const [responseApi, setResponseApi] = useState({ error: false, response: null, msg: null })
    const [visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(open => !open);
    //enviar dados para o servidor
    const conServer = async (dadosParaEnvio, metodo) => {
        await SERVER({
            rota: 'editar-venda',
            metodo,
            body: { fatura: dadosParaEnvio.fatura, pagamento: true },
            id: dadosParaEnvio._id,
            setResponseContext: setDadosAPI,
            chaveContext: 'buscarVendaAnuncios',
            setLoad: setLoad,
            setResponseApi: setResponseApi,
            openAlert: onDismiss

        })
    }

    //confirmação alerta info
    const [visibleCompAlert, setVisibleCompAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState();



    //todos os dados do Form
    const [getContrato, setContrato] = useState(() => {

        const novoDado = dados.fatura.map((val) => {

            return { ...val, editar: false }

        })

        return { ...dados, fatura: novoDado }
    });




    //gerar intervalo de data do contrato
    const [statusFatura, setStatusFatura] = useState(dados.status);



    //carregando dados recebido do fetch
    const [fetchErro, setfetchErro] = useState({ btn: false, res: true, tipo: 'carregando', msg: '' })

    //alerta fim do update alertToggle
    const [alertTg, setAlertTg] = useState(true);


    const [getnavEditarAnuncio, navEditarAnuncio] = useState(false);




    function alertToggle() {
        setAlertTg((to) => false)
        setContrato(fetchErro.data)
        setfetchErro((body) => {
            return {
                ...body,
                btn: false
            }
        })
    }

    function verificarFetch() {

        if (fetchErro.res) {

            if (fetchErro.tipo === 'carregando') {
                return (

                    <Progress
                        animated
                        value={100}
                        color="warning"
                    />

                )
            } else if (fetchErro.tipo === 'error') {

                return (
                    <div id='alert-fetch-sucess' className='sucessoFetch'>
                        <Alert color="danger">
                            <code style={{ fontSize: '.8rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>

                )
            } else if (fetchErro.tipo === 'sucesso') {


                return (

                    <div id={alertTg ? 'alert-fetch-sucess' : null} className='sucessoFetch'>
                        <Alert
                            isOpen={alertTg}
                            toggle={alertToggle}
                            color={fetchErro.metodoFetch === 'DELETE' ? 'danger' : 'success'}
                            onClick={() => {


                                //setModal((modalClose) => !modalClose)
                                window.location.reload()

                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                {fetchErro.msg}
                            </span>

                        </Alert>

                    </div>

                )
            }
        }
    }

    //colocar dados no localStorage
    localStorage.setItem('dadosBody', JSON.stringify(dados))
    function steLocalData() {

        return JSON.parse(localStorage.getItem('dadosBody'))

    }


    function gerarfaturas() {



        return (

            <div id='anunciante-faturas'>

                {getContrato?.fatura && getContrato.fatura.map((value, index) => {


                    return (
                        <div key={Date.now() + index}>

                            <Col className="border lista-faturas"
                                style={value.pago ? { backgroundColor: '#00ff3c3d' } : { backgroundColor: 'rgb(255 0 0 / 14%)' }}
                            >
                                <Input type="checkbox" id={`fatura-${index}`}
                                    defaultChecked={value.pago}

                                    onClick={(e) => {




                                        setContrato((val) => {



                                            let body = { ...getContrato }

                                            body.fatura[index].pago = !body.fatura[index].pago



                                            return body
                                        })


                                    }}
                                />
                                {' '}

                                <Label check for={`fatura-${index}`}>
                                    {`
                                    
                                    ${value.data} -
                                     ${PrecoBrl(Number(dados.valor))} -
                                     ${index + 1}/${dados.formPagemnto.split('-')[0] === 'MENSAL' ? dados.contratacao : 1} - 
                                    ${value.pago ? 'Pago' : 'A Pagar'} 
                                    
                                        `}
                                </Label>
                            </Col>



                        </div>
                    )
                })}
            </div>

        )
    }


    //vrificar confirmação
    function CompConfimacao(visibleCompAlert, setVisibleCompAlert) {
        return (
            <>
                <div id="comp-alert-confirm">
                    <Alert color="danger" isOpen={visibleCompAlert} style={{ maxWidth: '300px' }}>

                        {msgAlert.msg}

                        <div id='btn-alert-confirm'>




                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                    setVisibleCompAlert(false)


                                }}
                            >
                                Não
                            </Button>

                            <Button
                                color="success"
                                size="sm"
                                onClick={() => {

                                    setVisibleCompAlert(false)


                                    msgAlert.tipo === 'salvar' ?

                                        conServer(getContrato, 'PATCH')
                                        :
                                        navEditarAnuncio(true)

                                }}
                            >
                                Sim
                            </Button>
                        </div>

                    </Alert>
                </div>
            </>
        )
    }


    function BtnMercadoPago() {
       
        const coporMsg = `Olá! Segue o link de pagamento da sua fatura:\n----------------------------\n${dados?.linkPagamento}`

        const link = `https://wa.me/55${dados?.whatsapp}?text=${encodeURI(coporMsg)}`


        const copyToClipboard = () => {
            navigator.clipboard.writeText(dados?.linkPagamento).then(() => {
                alert("Link copiado para a área de transferência!");
            }).catch(err => {
                console.error("Erro ao copiar link", err);
            });
        };
        //
        return (
            <div className='section-btn-mp'>
                <button onClick={copyToClipboard} className="mp-button">
                    <img
                        src="https://www.stokeenvios.com.br/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FmercadoPago.0e24b993.png&w=2048&q=75"
                        alt="Mercado Pago"
                    />
                    Copiar link do pagamento
                </button>

                <a href={link} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                        alt="WhatsApp"
                    />
                    Compartilhar link do pagamento
                </a>
            </div>
        )
    }

    function gerarValorTotal(tipo) {

        // {PrecoBrl(Number(dados.valor) * Number(dados.contratacao))}
        let valorTotaldaFAtura = 0
        const forma = tipo.split('-')[0].replace(/[^\p{L}]/gu, '').toUpperCase();

        switch (forma) {
            case 'DIÁRIO':

                return valorTotaldaFAtura = PrecoBrl(Number(dados.valor) * Number(dados.contratacao))

                break;
            case 'MENSAL':

                return valorTotaldaFAtura = PrecoBrl(Number(dados.valor) * Number(dados.contratacao))
                break;

            case 'TRIMESTRAL':

                return valorTotaldaFAtura = PrecoBrl(Number(dados.valor) * Number(dados.contratacao))
                break;

            case 'ANUAL':

                return valorTotaldaFAtura = PrecoBrl(Number(dados.valor) * Number(dados.contratacao))
                break;

            default:
                break;

        }

        return valorTotaldaFAtura
    }




    return (


        <>
            <div id='fatura-anunciante-body' >

                <div className="box-shadow">
                    <div className="status-fatura"

                        style=
                        {
                            statusFatura === 'finalizado' ? { color: 'red' } : null ||
                                statusFatura === 'ativo' ? { color: 'green' } : null ||
                                    statusFatura === 'pausado' ? { color: '#f07b35' } : null
                        }

                    >
                        {`STATUS: ${statusFatura.toUpperCase()}`}

                    </div>
                    <div className='titulo-um'>Fatura</div>
                    <div className="infos-anunciante">
                        <div>Plano: {dados.plano}</div>

                        <div>Contrato {`${dados.formPagemnto.split('-')[0].toLowerCase()}: `}
                            {`${gerarQuantidade(dados.formPagemnto, dados.contratacao)}${NomeFormaPagamento(dados.formPagemnto, dados.contratacao) ? `/${NomeFormaPagamento(dados.formPagemnto, dados.contratacao)}` : ''} `} </div>
                    </div>

                </div>



                <div className="valor-fatura">

                    <div className='btns-fatura'>

                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStatusFatura('ativo')}

                        >
                            Ativar
                        </Button>

                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStatusFatura('pausado')}

                        >
                            Pausar
                        </Button>
                        <Button size='sm'
                            color="secondary"
                            onClick={() => setStatusFatura('finalizado')}

                        >
                            Finalizar
                        </Button>
                    </div>


                    <span>
                        <div className='titulo-um'>
                            {gerarValorTotal(dados.formPagemnto)}
                        </div>
                        <div className='tipo-pagamento-fatura'>Total</div>

                    </span>

                </div>


                  {dados?.linkPagamento && <BtnMercadoPago />} 


                <Row xs="2">
                    {gerarfaturas(steLocalData())}

                </Row>


            </div>



            <div id="rodape-fatura-fixo">
                <div className="box-footer ">
                    <Card style={{ border: 'none' }}>
                        <CardFooter>

                            <Button disabled={getLoad} color='danger' onClick={() => {

                                navEditarAnuncio(true)


                            }}>
                                Editar / Excluir
                            </Button>

                            <Button disabled={getLoad} color="success"
                                onClick={() => {



                                    setContrato((body) => {
                                        return {
                                            ...body,
                                            status: statusFatura
                                        }
                                    })

                                    setVisibleCompAlert(true)
                                    setMsgAlert(() => {
                                        return {
                                            tipo: 'salvar',
                                            msg: 'Você tem certeza de que deseja salvar as alterações?'
                                        }
                                    })

                                }}>
                                Salvar
                            </Button>


                        </CardFooter>
                    </Card>
                </div>
            </div>
            {getLoad && <Progress animated value={100} />}
            <SuccessEnvioDados danger={responseApi.error} menssagem={responseApi?.msg} visible={visible} onDismiss={onDismiss} />

            {fetchErro.btn && verificarFetch()}
            {visibleCompAlert && CompConfimacao(visibleCompAlert, setVisibleCompAlert)}
            {getnavEditarAnuncio && <Navigate to='/editar-anuncio' state={{ idVendas: dados }} />}
        </>
    )
}


