
//função HeadersGet
import { HeadersPost } from './headers'


import { DbNome } from './connDb'


export function UrlServer() {
    return process.env.REACT_APP_SERVER
}


export async function ConectURL(metodo, rota, msg, body, setfetchErro, id, setDados) {



    if (rota === 'proposta-personalizada') {

        const response = await fetch(`${UrlServer()}/${rota}`, {
            method: metodo,
            body: body ? JSON.stringify(body) : null,
            headers: HeadersPost()
        })

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            return setfetchErro(url)
        } else {
            return setfetchErro(false)

        }


    }


    setfetchErro((fetchErro) => {
        return {
            ...fetchErro,
            btn: true,
            res: true
        }
    })



    await fetch(`${UrlServer()}/${rota}?id=${id || ''}`, {
        method: metodo,
        body: body ? JSON.stringify(body) : null,
        headers: HeadersPost()
    })
        .then(response => response.json())
        .then((body) => {



            if (body.connected) {

                //setDados(body.response)



                return setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'sucesso',
                        msg: msg,
                        metodoFetch: metodo,
                        data: body.body,
                        load: true,
                        navegacao: true,
                        resFull: body.response
                    }
                })


            }



            if (!body.connected) {

                if (body.response === "Acesso negado") {
                    return setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: body.response
                        }
                    })
                }


                setfetchErro((fetchErro) => {
                    return {
                        ...fetchErro,
                        tipo: 'error',
                        metodoFetch: metodo,
                        msg: body.response || ''

                    }
                })
            }
        })
        .catch(err => {

            setfetchErro((fetchErro) => {
                return {
                    ...fetchErro,
                    tipo: 'error',
                    msg: 'Erro tente novamente mais tarde.',
                    metodoFetch: metodo
                }
            })
        })
}


export async function ConectURLPost(rota,
    getContrato, setloadResponse, setsucessoFetch,
    setaguardeTempo, setErrrorResponse, setDataResponse, setEmpresasGeo) {





    setloadResponse(true)



    await fetch(`${UrlServer()}/${rota}`, {
        method: 'POST',
        headers: HeadersPost(),
        body: JSON.stringify({ ...getContrato, dbNome: DbNome() })
    })
        .then(response => response.json())
        .then((body) => {



            if (body.response === "Acesso negado") {
                setErrrorResponse(true)
                setloadResponse(false)
                return
            }

            setloadResponse(false)
            setsucessoFetch(true)
            setDataResponse(body)


            if (rota === 'buscar-geo' && body.connected && !isNaN(body.response.lat) && !isNaN(body.response.lon)) {

                if (body.response.lat !== 0 && body.response.lon !== 0) {


                    return setEmpresasGeo({ lat: body.response.lat, lon: body.response.lon })
                }

            }

            setTimeout(() => { setaguardeTempo(true) }, 2000)

        })
        .catch(err => {
            setloadResponse(false)
            setErrrorResponse(true)
        })

}



export async function SERVER(obj) {

 

    const query = obj?.id ? `?id=${obj.id}` : '';

    obj.setLoad(true)

    await fetch(`${UrlServer()}/${obj.rota}${query}`, {
        method: obj.metodo,
        headers: obj?.formData ? HeadersPost({ formData: true }) : HeadersPost(),
        body: obj?.formData ? obj.body : JSON.stringify({ ...obj.body, dbNome: DbNome() })
    })
        .then(response => response.json())
        .then((body) => {



            obj.setResponseApi(
                {
                    error: !body.connected,
                    response: body.response,
                    msg: body.msg
                });

            if (body.connected) {
                obj.setResponseContext((dadosAPI) => {
                    return {
                        ...dadosAPI,
                        [obj.chaveContext]: body.response
                    }
                })
            }


        })
        .catch((error) => obj.setResponseApi({ error: true, msg: `Error: ${error.message}` })
        ).finally(() => {
            obj.setLoad(false)
            obj.openAlert(open => !open)
        })

}



