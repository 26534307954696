import { createContext, useState } from "react";


export const UserContext = createContext()

export const UserProvider = ({ children }) => {

  const [user, setUser] = useState(false)

  const [dadosAPI, setDadosAPI] = useState()
  const [tema, setTema] = useState('padrao')
  const [getUserDate, setUserDate] = useState(false)
 




  return (
    <UserContext.Provider value={{ user, setUser, dadosAPI, setDadosAPI, tema, setTema, getUserDate, setUserDate }}>
      {children }
    </UserContext.Provider>
  )

}


