import './cadastrarUsuario.css'
import { useState } from 'react';
import {
    Button, Col, Label, Form, Row, FormGroup,
    Card, CardFooter,
    FormFeedback, Input
} from 'reactstrap';

//importar função fetch
import { ConectURLPost } from '../../../util/conect'
import { Navigate } from 'react-router';


export default function CadastrarUser() {




    //fechar cadastro de cliente    


    //todos os dados do Form    
    const [data, setData] = useState();
    const [loadResponse, setloadResponse] = useState();
    const [sucessoFetch, setsucessoFetch] = useState();
    const [aguardeTempo, setaguardeTempo] = useState();
    const [ErrrorResponse, setErrrorResponse] = useState();
    const [dataResponse, setDataResponse] = useState();


    async function Cadastrar() {

        await ConectURLPost(
            'cadastrar-usuario',
            data,
            setloadResponse,
            setsucessoFetch,
            setaguardeTempo,
            setErrrorResponse,
            setDataResponse
        )


    }




    return (

        <div id="cadastro-de-user">


            {dataResponse && (<div className='msg-res'>Erro ao cadastrar o usuário!  {dataResponse?.msg} </div>)}


            {dataResponse?.connected && <Navigate to={'/listar-usuarios'} replace={true} />}

            <div id='cadastrar-cliente'>

                <div >

                    <Form id='dadosDaEmpresa'>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nomeEmpresa">
                                        Nome da Empresa:
                                    </Label>

                                    <Input
                                        type="text"
                                        name="nomeEmpresa"
                                        placeholder="Nome da empresa"
                                        id="nomeEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    nomeEmpresa: e.target.value
                                                }
                                            })
                                        }}
                                    />


                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="responsavelEmpresa">
                                        Responsável:
                                    </Label>

                                    <Input

                                        type="text"
                                        name="responsavelEmpresa"
                                        id="responsavelEmpresa"
                                        placeholder="Nome do Responsável"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    responsavelEmpresa: e.target.value
                                                }
                                            })
                                        }}

                                    />


                                </FormGroup>
                            </Col>
                        </Row>


                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="documentoEmpresa">
                                        CPF / CNPJ:
                                    </Label>
                                    <Input

                                        type="text"
                                        name="documentoEmpresa"
                                        id="documentoEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    documentoEmpresa: e.target.value
                                                }
                                            })
                                        }}


                                    />
                                    <FormFeedback >
                                        Documento da empresa é necessário!
                                    </FormFeedback>

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="whatsApp">
                                        WhatsApp:
                                    </Label>


                                    <Input

                                        type="number"
                                        name="whatsApp"
                                        id="whatsApp"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    whatsApp: e.target.value
                                                }
                                            })
                                        }}

                                    />
                                    <FormFeedback >
                                        WhatsApp é necessário!
                                    </FormFeedback>

                                </FormGroup>
                            </Col>
                            <Col >
                                <FormGroup>
                                    <Label for="enderecoEmpresa">
                                        Endereço:
                                    </Label>

                                    <Input

                                        type="text"
                                        name="enderecoEmpresa"
                                        id="enderecoEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    enderecoEmpresa: e.target.value
                                                }
                                            })
                                        }}

                                    />



                                </FormGroup>
                            </Col>

                            <Col >
                                <FormGroup>
                                    <Label for="bairroEmpresa">
                                        Bairro::
                                    </Label>

                                    <Input

                                        type="text"
                                        name="bairroEmpresa"
                                        id="bairroEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    bairroEmpresa: e.target.value
                                                }
                                            })
                                        }}

                                    />



                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="cidadeEmpresa">
                                        Cidade:
                                    </Label>

                                    <Input

                                        type="text"
                                        name="cidadeEmpresa"
                                        id="cidadeEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    cidadeEmpresa: e.target.value
                                                }
                                            })
                                        }}

                                    />



                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="estadoEmpresa">
                                        Estado:
                                    </Label>

                                    <Input

                                        type="text"
                                        name="estadoEmpresa"
                                        id="estadoEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    estadoEmpresa: e.target.value
                                                }
                                            })
                                        }}

                                    />




                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="cepEmpresa">
                                        CEP:
                                    </Label>

                                    <Input

                                        type="number"
                                        name="cepEmpresa"
                                        id="cepEmpresa"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    cepEmpresa: e.target.value
                                                }
                                            })
                                        }}

                                    />


                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="senha">
                                        Senha:
                                    </Label>

                                    <Input

                                        type="password"
                                        name="senha"
                                        id="senha"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    senha: e.target.value
                                                }
                                            })
                                        }}

                                    />



                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="confirmSenha">
                                        Confirmar Senha:
                                    </Label>

                                    <Input

                                        type="password"
                                        name="confirmSenha"
                                        id="confirmSenha"
                                        onChange={(e) => {
                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    confirmSenha: e.target.value
                                                }
                                            })
                                        }}

                                    />




                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">
                                        Username:
                                    </Label>

                                    <Input
                                        style={{ textTransform: "lowercase" }}
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={data?.email.replace(/[^a-z]/g, '') || ''}
                                        onChange={(e) => {

                                            setData((dados) => {
                                                return {
                                                    ...dados,
                                                    email: e.target.value.replace(/[^a-z]/g, '')
                                                }
                                            })
                                        }}

                                    />



                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">
                                        Site da Propsota: {`${data?.email || ''}.stokeplay.com.br`}
                                    </Label>
                                </FormGroup>
                            </Col>

                        </Row>

                        <FormGroup check>

                            <Input
                                type="checkbox"
                                name="empresaAtiva"
                                id="empresaAtiva"
                                onChange={(e) => {
                                    setData((dados) => {
                                        return {
                                            ...dados,
                                            empresaAtiva: e.target.checked
                                        }
                                    })
                                }}

                            />

                            <Label
                                check
                                for="empresaAtiva"
                            >
                                Empresa ativa
                            </Label>



                        </FormGroup>

                    </Form>
                </div>



                <div className="box-footer">
                    <Card style={{ border: 'none' }}>
                        <CardFooter>


                            <Button disabled={loadResponse} color="success" onClick={() => {


                                Cadastrar()

                            }}>
                                {loadResponse ? 'Salvando...' : 'Cadastrar'}
                            </Button>





                        </CardFooter>
                    </Card>
                </div>


            </div>

        </div>


    )
}


