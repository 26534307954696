import './clientes.css'
import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';
import {
    Progress, Alert, Modal, ModalHeader, ModalBody, Form, Col, Label
    , Input, FormGroup
} from 'reactstrap';

//Retirar caracteres especial 
import { RemoverCaracteres } from '../../../util/tools'

//dados do anuciante
import Cliente from './cliente';

//importar urlGlobal
import { UrlServer } from '../../../util/conect';

//função para buscar e filtrar buscas 
import { SearchFilter, PrintCard } from '../../../util/searchFilter'

//função HeadersGet
import { HeadersGet } from '../../../util/headers'

//context todos os dados da api
import { UserContext } from '../../../context/UserContext';

//const { dadosAPI, setDadosAPI} = useContext(UserContext)

export default function ListarClientes() {

    const { dadosAPI, setDadosAPI } = useContext(UserContext)

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [getDados, setDados] = useState()

    const [listarCliente, setlistarCliente] = useState()

    //Fetch
    const [fetchErro, setfetchErro] = useState({ res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)

    //buscar
    const [buscarCliente, setbuscarCliente] = useState('')


    useEffect(() => {

        if (!dadosAPI?.buscarClienteListar) {


            fetch(`${UrlServer()}/buscar-cliente`, HeadersGet())
                .then(response => response.json())
                .then(data => {



                    if (data.connected) {

                        if (data.response === "Acesso negado") {
                            return setfetchErro((err) => {
                                return {
                                    ...err,
                                    tipo: 'error',
                                    msg: data.response
                                }
                            })
                        }

                        //ordem alfabetica
                        data.response.sort((a, b) => a.nomeEmpresa
                            .localeCompare(b.nomeEmpresa, 'pt', { sensitivity: 'base' }));

                        setdadosFetch(data.response)

                        //colocar todos os dados da API no context
                        setDadosAPI((dadosAPI) => {

                            return {
                                ...dadosAPI,
                                buscarClienteListar: data.response
                            }
                        })

                        setfetchErro((err) => {
                            return {
                                ...err,
                                res: false
                            }
                        })

                    } else {
                        setfetchErro((err) => {
                            return {
                                ...err,
                                tipo: 'error',
                                msg: data.msg
                            }
                        })
                    }

                })
                .catch((error) => {

                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: 'Erro interno'
                        }
                    })



                })
        } else {

            
            
            setdadosFetch(dadosAPI.buscarClienteListar)
            setfetchErro((err) => {
                return {
                    ...err,
                    res: false
                }
            })

        }

        fetch(`${UrlServer()}/buscar-venda`, HeadersGet())
            .then(response => response.json())
            .then(data => setlistarCliente(data.response))

    }, [dadosAPI])



    if (fetchErro.res) {
        if (fetchErro.tipo === 'carregando') {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color="warning"
                        />
                    </div>
                </div>
            )
        } else if (fetchErro.tipo === 'error') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com o servidor.
                            <br></br>                           
                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        }
    }


    if (dadosFetch?.length === 0) {
        return (
            <>
                <div className="stk-body">

                    <Alert color="danger" isOpen={true} >
                        Necessário cadastrar um cliente primeiro.
                        <br></br>
                        <Link to="/cadastrar-cliente"> Clique aqui para cadastrar.</Link>
                    </Alert>

                </div>
            </>
        )
    }

    //dadosFetch

    function contarClientesAtivos() {


        let id_vendas = []
        let id_cliente = []
        let quantidade = 0
        let clienteCadastrado = []
        let clienteAtivo

        //clientes com anuncios
        listarCliente.map((val) => {

            if (val.status === 'ativo') {
                id_vendas.push(val.id_cliente)
            }

        })

        //listar clintes

        dadosFetch.map((val) => {

            if (val.empresaAtiva) {
                id_cliente.push(val._id)
                clienteCadastrado.push(val)
            }

        })

        let quantClient = []

        id_vendas.map((val) => {

            id_cliente.map((value) => {
                if (value === val) {

                    if (!quantClient.includes(value)) {
                        quantClient.push(value)
                        quantidade += 1
                    }

                }

            })
        })

        if (quantidade === 0) {

            clienteAtivo = 'Nenhum cliente com anúncio ativo'

        } else if (quantidade === 1) {

            clienteAtivo = quantidade + ' cliente com anúncio ativo'

        } else {

            clienteAtivo = quantidade + ' clientes com anúncios ativos'
        }

        return {
            ativo: clienteAtivo,
            total: clienteCadastrado.length === 0 ? 'Nenhum cliente cadastrado ativo' :
                clienteCadastrado.length > 1 ?
                    clienteCadastrado.length + ' clientes cadastrados ativos' :
                    clienteCadastrado.length + ' cliente cadastrado ativo'
        }

    }

    return (
        <>

            <div id='listar-clientes' className='stk-body'>
                <div className="titulo-um">
                    Clientes
                </div>

                <div className="box-shadow">
                    <div className="box-busca">
                        <Form>

                            <Col>
                                <FormGroup>
                                    <Label for="buscarCliente">
                                        Buscar cliente:
                                    </Label>
                                    <Input
                                        className='icone-input-buscar'
                                        id="buscarCliente"
                                        name="buscarCliente"
                                        type="search"
                                        placeholder='Buscar cliente'
                                        onChange={(e) => {

                                            setbuscarCliente(RemoverCaracteres(e.target.value))


                                        }}

                                    />
                                </FormGroup>
                            </Col>

                            <FormGroup>
                            </FormGroup>
                        </Form>
                    </div>

                </div>

                <div className="titulo-um">{listarCliente && contarClientesAtivos().ativo}</div>
                <div className="titulo-dois">{listarCliente && contarClientesAtivos().total}</div>
                <div className="">
                    <div className="box-empresa">

                        {buscarCliente.length > 0 ? (
                            SearchFilter(dadosFetch, buscarCliente, `buscarCliente`, 'nomeEmpresa').map((body, ind) => {

                                return (
                                    <span key={ind} className='box-empresa-span'>
                                        {PrintCard(body, setDados, setModal, modal, `cadastrar-cliente?cliente=${buscarCliente}`, 'cliente', body.src)}

                                    </span>
                                )
                            })
                        ) : (

                            dadosFetch?.map((body, ind) => {
                                return (
                                    <span key={ind} className='box-empresa-span'>
                                        {PrintCard(body, setDados, setModal, modal, buscarCliente, 'cliente', body.src)}

                                    </span>
                                )

                            })
                        )
                        }


                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>{getDados?.nomeEmpresa || ''}</ModalHeader>
                <ModalBody>
                    <Cliente dados={getDados} setModal={setModal} modal={modal} />
                </ModalBody>

            </Modal>
        </>
    )
}
