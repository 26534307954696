const { Input } = require("reactstrap");

const FormInput = ({ register, name, onchange, getGeo,  ...rest }) => {

  const { ref, ...registerField } = register(name, { required: name === 'empresaAtiva' || name === 'planoAtivo' ? false : true });

  return <Input valid={false} innerRef={ref} {...registerField} {...rest} onChange={(e) => {
    getGeo && onchange((body) => {
      return {
        ...body,
        [name]: e.target.value
      }
    })
  }} />;
};

export default FormInput;