
import './nossosNumeros.css'

import IndexContador from './contador/indexContador'


export default function NossosNumeros({ dadosEmpresa }) {


    //calcular Fluxo de pessoas
    function fluxoPessoas() {

       
        return dadosEmpresa.ondeEstamos.reduce((acc, item) => acc + Number(item.fluxoMedio), 0);


    }

    



    return (
        <>

            <div id="containerNossosNumeros">
                <div>
                    <div id="TituloPlano" className="nossosNumeros">
                        Nossos números
                    </div>
                </div>
                <div className='boxPai'>
                    <div className="boxNumeros">

                        <div className="quantNumeros">
                            <div className="numeroQuant" style={{ color: dadosEmpresa?.corSelecionada }}>
                                <IndexContador alvo={dadosEmpresa?.quantTelas ? dadosEmpresa?.quantTelas : 0} />
                            </div>
                            <div className="textoQuant fw-100">{dadosEmpresa?.quantTelas > 1 ? 'Telas' : 'Tela'}</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>

                        <div className="quantNumeros">
                            <div className="numeroQuant" style={{ color: dadosEmpresa?.corSelecionada }}>
                                <IndexContador alvo={dadosEmpresa?.quantClientes ? dadosEmpresa?.quantClientes : 0} />
                            </div>
                            <div className="textoQuant fw-100">{dadosEmpresa?.quantClientes > 1 ? 'Clientes atendidos' : 'Cliente atendido'}</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>

                        <div className="quantNumeros ">
                            <div className="numeroQuant" style={{ color: dadosEmpresa?.corSelecionada }}>
                                <IndexContador alvo={dadosEmpresa?.quantCidade ? dadosEmpresa?.quantCidade : 0} />
                            </div>
                            <div className="textoQuant fw-100">{dadosEmpresa?.quantCidade > 1 ? 'Cidades' : 'Cidade'}</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>

                        <div className="quantNumeros">
                            <div className="numeroQuant numeroQuantFluxo fw-100" style={{ color: dadosEmpresa?.corSelecionada}}>
                                <IndexContador alvo={!isNaN(fluxoPessoas()) ? fluxoPessoas() : 0} />
                            </div>
                            <div className="textoQuant fw-100">Fluxo de pessoas</div>
                            <div className="tracoQuant" style={{ backgroundColor: dadosEmpresa?.corSelecionada }}></div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}