
import './novoAnuncio.css'

import React, { useEffect, useState , useContext} from 'react';
import { Link } from 'react-router-dom';
import {
    Progress, Alert, Modal, ModalHeader, ModalBody, Form, Col, Label
    , Input, FormGroup
} from 'reactstrap';



//Retirar caracteres especial 
import { RemoverCaracteres } from '../../util/tools'

//função para buscar e filtrar buscas 
import { SearchFilter, PrintCard } from '../../util/searchFilter'

//dados do anuciante
import CadastroDeAnuncio from './cadastroDeAnuncio';

//context todos os dados da api
import { UserContext } from '../../context/UserContext';

import { HeadersGet } from '../../util/headers';
import { UrlServer } from '../../util/conect';
 
export default function NovoAnuncio() {

    const { dadosAPI, setDadosAPI} = useContext(UserContext)

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [getDados, setDados] = useState()

    //Fetch
    const [fetchErro, setfetchErro] = useState({ res: true, tipo: 'carregando', msg: '' })
    const [dadosFetch, setdadosFetch] = useState(false)


    //buscar
    const [buscarCliente, setbuscarCliente] = useState('')




    useEffect(() => {

        
        if (!dadosAPI?.buscarClienteListar) { 
    

        fetch(`${UrlServer()}/buscar-cliente`, HeadersGet())
            .then(response => response.json())
            .then(data => {


                if (data.connected) {

                    data.response.sort((a, b) => a.nomeEmpresa
                        .localeCompare(b.nomeEmpresa, 'pt', { sensitivity: 'base' }));

                    setdadosFetch(data.response)

                     //colocar todos os dados da API no context
                     setDadosAPI((dadosAPI) => {

                        return {
                            ...dadosAPI,
                            buscarClienteListar: data.response
                        }
                    })

                    setfetchErro((err) => {
                        return {
                            ...err,
                            res: false
                        }
                    })
                } else {
                    setfetchErro((err) => {
                        return {
                            ...err,
                            tipo: 'error',
                            msg: data.msg
                        }
                    })
                }

            })
            .catch((error) => {

                setfetchErro((err) => {
                    return {
                        ...err,
                        tipo: 'error',
                        msg: 'Não é possível conectar-se'
                    }
                })

            })

        }else{
            setdadosFetch(dadosAPI.buscarClienteListar)
            setfetchErro((err) => {
                return {
                    ...err,
                    res: false
                }
            })
            
        }
    }, [])



    if (fetchErro.res) {
        if (fetchErro.tipo === 'carregando') {
            return (
                <div className='stk-body'>
                    <div className="carregando-fetch" style={{ paddingBottom: '10px' }}>
                        <Progress
                            animated
                            value={100}
                            color='warning'
                        />
                    </div>
                </div>
            )
        } else if (fetchErro.tipo === 'error') {
            return (
                <div className='stk-body'>
                    <div className="box-shadow-error" style={{ paddingBottom: '10px' }}>
                        <Alert color="danger">
                            Não foi possivel se conectar com oservidor.
                            <br></br>
                           
                            <code style={{ fontSize: '.7rem' }}>
                                {fetchErro.msg}
                            </code>

                        </Alert>
                    </div>
                </div>
            )
        }
    }


    if (dadosFetch.length === 0) {
        return (
            <>
                <div className="stk-body">

                    <Alert color="danger" isOpen={true} >
                        Necessário cadastrar um cliente primeiro.
                        <br></br>
                        <Link to="/cadastrar-cliente"> Clique aqui para cadastrar.</Link>
                    </Alert>

                </div>
            </>
        )
    }

    return (
        <>

            <div id='NovoAnuncio' className='stk-body'>
                <div className="titulo-um">
                    Novo anúncio
                </div>

                <div className="box-shadow">
                    <div className="box-busca">
                        <Form>

                            <Col>
                                <FormGroup>
                                    <Label for="plano">
                                        Buscar cliente:
                                    </Label>
                                    <Input
                                        className='icone-input-buscar'
                                        id="buscarCliente"
                                        name="buscarCliente"
                                        type="search"
                                        placeholder='Buscar cliente'
                                        onChange={(e) => {
                                            setbuscarCliente(RemoverCaracteres(e.target.value))


                                        }}

                                    />
                                </FormGroup>
                            </Col>

                            <FormGroup>
                            </FormGroup>
                        </Form>
                    </div>

                </div>

                <div>
                    <div className="box-empresa">



                        {buscarCliente.length > 0 ? (
                            SearchFilter(dadosFetch, buscarCliente, buscarCliente, 'nomeEmpresa').map((body, ind) => {
                                return (
                                    <span key={ind} className='box-empresa-span'>
                                        {PrintCard(body, setDados, setModal, modal, `cadastrar-cliente?cliente=${buscarCliente}`, 'Cliente', body.src)}

                                    </span>
                                )
                            })
                        ) :
                            (

                                dadosFetch?.map((body, ind) => {
                                    return (
                                        <span key={ind} className='box-empresa-span'> 
                                            {body.empresaAtiva && PrintCard(body, setDados, setModal, modal, null, 'Cliente', body.src)}

                                        </span>
                                    )

                                })
                            )
                        }



                    </div>
                </div>
            </div>


            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>{`Novo anúncio: ${getDados?.nomeEmpresa}`}</ModalHeader>
                <ModalBody>
                    <CadastroDeAnuncio dados={getDados} setModal={setModal} modal={modal} />
                </ModalBody>

            </Modal>
        </>
    )
}
